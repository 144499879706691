import { Button3d } from 'components/button/Button';
import Image from 'assets/onboarding/onboardImage3.png';
import Image2 from 'assets/onboarding/onboardImage4.png';
import { useTranslation } from 'react-i18next';

interface Prop {
	setOnboardingView: (e: string) => void;
}

export function Onboarding2({ setOnboardingView }: Prop) {
	const { t } = useTranslation('common');

	return (
		<div className="relative w-full h-screen overflow-hidden play-background">
			<div className="relative py-6 h-full overflow-scroll">
				<button
					onClick={() => setOnboardingView('connect')}
					className="absolute right-4 z-1 text-[1rem] w-[4rem] capitalize text-primary h-[4rem] bg-[#FFFFFF0A] border-2 border-[#FFFFFF33] rounded-full"
				>
					{t('util.skip')}
				</button>

				<img
					src={Image}
					alt="Image"
					className="absolute top-12 left-0 w-[auto] h-[30%] object-contain"
				/>

				<div className="flex flex-col h-full w-full max-w-[320px] mx-auto gap-4 justify-center items-center">
					<div>
						<p className="text-primary text-[2.5rem] leading-tight font-medium text-center">
							{t('onboard.playAnd')}
						</p>
						<p className="text-primary text-[2.5rem] leading-tight font-medium text-center">
							{t('onboard.winPrizes')}
						</p>
					</div>

					<div className="flex flex-col w-full gap-2">
						<p className="text-primary text-[1rem] font-semibold text-center">
							{t('onboard.compete')}
						</p>
					</div>

					<div className="flex items-center justify-center gap-2">
						<div className="w-[8px] h-[8px] rounded-full bg-[#FFFFFF5E]" />
						<div className="w-[8px] h-[8px] rounded-full bg-[#84C70D] outline outline-[#84C70D] outline-offset-2 mx-1" />
						<div className="w-[8px] h-[8px] rounded-full bg-[#FFFFFF5E]" />
					</div>
				</div>

				<img
					src={Image2}
					alt="Image"
					className="absolute bottom-0 right-0  w-[auto]"
				/>
			</div>
			<div className="absolute bottom-12 left-0 right-0 z-10 w-full px-4">
				<Button3d
					text={t('util.next')}
					disabled={false}
					onClick={() => {
						setOnboardingView('connect');
					}}
					className={`font-semibold capitalize w-full flex items-center justify-center h-[56px] mb-5`}
				/>
			</div>
		</div>
	);
}
