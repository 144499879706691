import { useRef, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { arrowLeft } from 'assets';

const ChatModal = () => {
	const navigate = useNavigate();
	const iframeRef = useRef<HTMLIFrameElement | null>(null);
	const height = window.screen.height * 0.7;
	const { state } = useLocation();
	const script = state.script;

	const [iframeLoaded, setIframeLoaded] = useState(false);
	const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

	useEffect(() => {
		if (iframeLoaded) {
			console.log('loaded', iframeLoaded);
			// Inject the script into the iframe
			const iframe = iframeRef.current;
			console.log('iframe', iframe);
			if (iframe) {
				// Add an event listener to wait for the iframe to load

				iframe.onload = () => {
					// Now it's safe to access iframe.contentDocument
					console.log('iframe.onloadi');
					if (iframe.contentDocument) {
						console.log('iframe.contentDocument loaded');
						const scriptElement =
							iframe.contentDocument.createElement('script');
						scriptElement.innerHTML = script;
						iframe.contentDocument.body.appendChild(scriptElement);
					}
				};
			}
		}
	}, [iframeLoaded, script]);

	const handleIframeLoad = () => {
		setTimeout(() => {
			if (iframeRef.current && iframeRef.current.contentDocument) {
				// Access iframe.contentDocument here
				//console.log('it hittttttttt');
			}
		}, 1000); // Adjust the delay as needed
		setIframeLoaded(true);
	};

	useEffect(() => {
		// Scroll to the element with the id 'customerSupport' on page load
		const customerSupportElement = document.getElementById('customerSupport');

		if (customerSupportElement) {
			customerSupportElement.scrollIntoView({ behavior: 'smooth' });
		}
	}, []); // Empty dependency array ensures the effect runs only once on mount

	return (
		<div>
			<div
				className="max-w-[710px] mx-auto px-3 flex flex-col"
				style={{ flex: 1 }}
			>
				<div
					id="customerSupport"
					className="flex gap-4 mt-8 mb-4 justify-center items-center relative"
					style={{ flex: 0.2 }}
				>
					<button
						type="button"
						onClick={() => navigate(-1)}
						data-testid="chat-page-back"
						className="absolute left-0 bg-[#1D202B] rounded-full lg:px-5 px-4 py-2 flex items-center gap-2 cursor-pointer outline-none"
					>
						<img src={arrowLeft} className="w-6 h-6" alt="left" />
						{isMobile() ? (
							''
						) : (
							<span className="text-sm sm:text-lg text-grey font-bold">
								Back
							</span>
						)}
					</button>
					<h2 className="text-primary text-2xl font-bold focus:outline-none focus:ring focus:border-primary">
						Customer Support
					</h2>
				</div>
				<div
					className="rounded-[10px] bg-[#1D202B] border-2 border-[#232838] py-4 px-4 mb-4 mt-2"
					style={{ flex: 0.8 }}
				>
					<iframe
						ref={iframeRef}
						onLoad={handleIframeLoad}
						title="Chat IFrame"
						src="https://tawk.to/chat/64da2888cc26a871b02f29e2/1h7q2sm6k" // Replace with your Tawk.to URL
						style={{ width: '100%', height: height }}
					/>
				</div>
			</div>

			{/* <div id="tawk-container"></div> */}
		</div>
	);
};

export default ChatModal;

// src="https://tawk.to/chat/64da2888cc26a871b02f29e2/1h7q2sm6k" // Replace with your Tawk.to URL
