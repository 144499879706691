import { free_sub, basic_sub, premium_sub } from 'assets';

interface Prop {
	isActiveSubExist: any;
}

export const UserSubscriptionsModal = ({ isActiveSubExist }: Prop) => {
	const subscriptions = [
		{ title: 'Free', description: 'Play games for free', icon: free_sub },
		{
			title: 'Basic',
			description: 'Basic Subscription plan',
			icon: basic_sub,
		},
		{
			title: 'Premium',
			description: '6 Months Subscription',
			icon: premium_sub,
		},
	];

	return (
		<div className="h-full w-full">
			<main className="absolute bottom-0 pb-[3rem] w-full flex flex-col items-center bg-[#0F121D] rounded-tl-[22px] rounded-tr-[22px] border-2 border-[#ffffff] border-opacity-[8%] ">
				<div className="w-full px-5 md:px-6 mt-[2rem] h-full flex flex-col items-center">
					<p className="w-full text-primary capitalize text-[1.5rem] font-medium mb-10">
						Subscription
					</p>

					{subscriptions?.length > 0 &&
						subscriptions?.map((plan, index) => (
							<div
								key={index}
								className={`${
									(!isActiveSubExist &&
										plan.title.toLowerCase().includes('free')) ||
									isActiveSubExist?.subscriptionPlanId?.title
										.toLowerCase()
										.includes(plan.title.toLowerCase())
										? 'border-green'
										: 'border-[#FFFFFF14]'
								} relative flex items-center gap-5 w-full px-3 bg-[#FFFFFF0A] border h-[94px] rounded-[12px] mb-4`}
							>
								<img
									src={plan?.icon}
									className="h-[40px] w-[40px]"
									alt="icon"
								/>
								<div>
									<p className="text-primary text-[1.5rem] font-medium">
										{plan?.title}
									</p>
									<p className="text-[#FFFFFF80] text-[14px]">
										{plan?.description}
									</p>
								</div>

								{isActiveSubExist?.subscriptionPlanId?.title
									.toLowerCase()
									.includes(plan.title.toLowerCase()) && (
									<div
										className="bg-[#E5FFC3] py-2 px-4 max-w-[142px] w-full absolute top-0 right-0 italic text-[1rem] text-center font-semibold"
										style={{ borderRadius: '0px 13px 0px 75.75px' }}
									>
										Active
									</div>
								)}

								{!isActiveSubExist &&
									plan.title.toLowerCase().includes('free') && (
										<div
											className="bg-[#E5FFC3] py-2 px-4 max-w-[145px] w-full absolute top-0 right-0 italic text-[1rem] text-center font-semibold"
											style={{ borderRadius: '0px 13px 0px 75.75px' }}
										>
											Active
										</div>
									)}
							</div>
						))}
				</div>
			</main>
		</div>
	);
};
