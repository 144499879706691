export function getUserFromSession() {
	const user = localStorage.getItem('game-user-obj');
	if (user) {
		return JSON.parse(user);
	}
}

// export function getAccessToken() {
// 	const token = localStorage.getItem('accessToken');
// 	return token ? token : '';
// }

// export function getAccessSecret() {
// 	const team = localStorage.getItem('accessSecret');
// 	return team;
// }

export function isAuthenticatedUser() {
	const authUser = localStorage.getItem('game-user-obj');
	return authUser ? true : false;
}
