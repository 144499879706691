import { apiService } from 'api/axios';

export const updateMemberProfile = async (data: object) => {
	const payload = { ...data };
	const response = await apiService(data).patch({
		url: '/member/profile',
		payload,
	});
	return response;
};
