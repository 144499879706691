import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInitialState {
	gameData: object;
}

export const initialState: IInitialState = {
	gameData: {},
};

export const publicSlice = createSlice({
	name: 'hammer-games',
	initialState,
	reducers: {
		getGameData: (state, action: PayloadAction<object>) => {
			state.gameData = action.payload;
		},
	},
});

export const publicActions = publicSlice.actions;
export const publicReducer = publicSlice.reducer;
