import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// type IObject = {
// 	obj: { [key: string]: string | number };
// };

interface IInitialState {
	userProfile: { [key: string]: string | number };
	userGems: string | number;
	recentWinners: { [key: string]: string | number }[];
	referralInfo: {
		gemRewardConditionDescription: string;
		howMuchForEachReferral: any;
		totalGemsRewardToClaim: any;
		totalReferralBonusEarned: any;
	};
	collectionData: object;
	showAnnouncement: boolean;
	userSubscription: { [key: string]: string | number };
}

export const initialState: IInitialState = {
	userProfile: {},
	userGems: '',
	recentWinners: [],
	referralInfo: {
		gemRewardConditionDescription: '',
		howMuchForEachReferral: 0,
		totalGemsRewardToClaim: 0,
		totalReferralBonusEarned: 0,
	},
	collectionData: [],
	showAnnouncement: true,
	userSubscription: {},
};

export const userSlice = createSlice({
	name: 'hammer-games',
	initialState,
	reducers: {
		addUserProfile: (
			state,
			action: PayloadAction<{ [key: string]: string | number }>
		) => {
			state.userProfile = action.payload;
		},
		addUserGems: (state, action: PayloadAction<string | number>) => {
			state.userGems = action.payload;
		},
		addReecentWinners: (
			state,
			action: PayloadAction<{ [key: string]: string | number }[]>
		) => {
			state.recentWinners = action.payload;
		},
		addReferralData: (state, action: PayloadAction<{ [key: string]: any }>) => {
			state.referralInfo = { ...state.referralInfo, ...action.payload };
		},
		addUserCollections: (state, action: PayloadAction<any>) => {
			state.collectionData = action.payload;
		},
		setShowAnnouncement: (state, action: PayloadAction<boolean>) => {
			state.showAnnouncement = action.payload;
		},
		addUserSubscription: (
			state,
			action: PayloadAction<{ [key: string]: string | number }>
		) => {
			state.userSubscription = action.payload;
		},
	},
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;
