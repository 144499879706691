import { apiService } from './axios';

export const getMemberProfile = async () => {
	const response = await apiService().get('/member/profile');
	return response;
};

export const updateMemberProfile = async (data: object) => {
	const payload = { ...data };
	const response = await apiService(payload).patch({
		url: '/member/profile',
		payload,
	});
	return response;
};

export const changeUserPasswordApi = async (data: object) => {
	const payload = { ...data };
	const response = await apiService(payload).post({
		url: '/member/profile/change-password',
		payload,
	});
	return response;
};

export const memberReferrals = () =>
	apiService().get('/member/referral-system');

export const referralSystemTotal = () =>
	apiService().get('/member/referral-system/total');

export const memberReferralList = async () => {
	const response = await apiService().post({
		url: '/member/game-center/referrals/list',
	});
	return response;
};

export const getReferralList = async () => {
	const response = await apiService().get('/member/game-center/referrals/list');
	return response;
};
