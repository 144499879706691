import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SpinInitialState {
	settings: object;
	activeGem: object;
	activePowerUp: string;
	productQuantity: number;
}

export const initialState: SpinInitialState = {
	settings: {
		sound: false,
		music: false,
		animation: false,
		newUser: true,
		theme: 'default',
	},
	activeGem: {},
	activePowerUp: '',
	productQuantity: 1,
};

export const spinSettingsSlice = createSlice({
	name: 'hammer-games',
	initialState,
	reducers: {
		setSpinSettings: (state, action: PayloadAction<object>) => {
			state.settings = action.payload;
		},
		setActiveGem: (state, action: PayloadAction<object>) => {
			state.activeGem = action.payload;
		},
		setActivePowerUp: (state, action: PayloadAction<string>) => {
			state.activePowerUp = action.payload;
		},
		setProductQuantity: (state, action: PayloadAction<number>) => {
			state.productQuantity = action.payload;
		},
	},
});

export const spinSettingsActions = spinSettingsSlice.actions;
export const spinSettingsReducer = spinSettingsSlice.reducer;
