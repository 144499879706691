import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import TextInput from 'components/inputs/text';
import { AuthFormAction } from 'components/forms/auth/layout';
import { Button } from 'components/button/Button';
import { PasswordStrength } from 'components/common/PasswordStrength';
import { ReactComponent as Back } from 'assets/icons/arrow-left-circle.svg';
import { checkVasUserApi, loginUserApi } from 'api/apiRequest';
import { authActions } from 'store/slices/authSlice';
import { cleanInput } from 'utils/cleanInput';
import { toast } from 'react-toastify';
import successHandler from 'handlers/successHandler';
import errorHandler from 'handlers/errorHandler';

export const VasCreatePasswordPage: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [disabled, setDisabled] = useState(true);
	const { userPhone } = useSelector((state: any) => state.authReducer);
	const [data, setData] = useState({
		username: '',
		password: '',
		userPhoneCountryCode: 'NG',
	});
	const phone_pattern = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;

	const handleChange = (e: any) => {
		const { value } = e.target;
		setConfirmPassword(value);
		if (value === password && value !== '') {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	};

	// Setup mutation for registering a user with error handling and redirection on success
	const { isLoading, mutate } = useMutation(checkVasUserApi, {
		onError: (error: Error) => {
			console.log(error);
			if (
				error.message.toLowerCase().includes('phone number already registered')
			) {
				const formData = {
					username: userPhone,
					password: password,
					userPhoneCountryCode: 'NG',
				};
				cleanInput(formData);
				loginUser(formData);
			} else {
				console.log('This is so wrong');
			}
		},
		onSuccess: (res) => {
			// Redirect based on the type of registration (email or phone)
			console.log(res);
			if (res.data?.subscription?.message === 'done') {
				const formData = {
					username: userPhone,
					password: password,
				};
				cleanInput(formData);
				loginUser(formData);
			}
		},
		retry: 0,
	});

	const { mutate: loginUser } = useMutation(loginUserApi, {
		onError: (error: any) => {
			if (error && error?.action === 'not_verified_email') {
				if (phone_pattern.test(data?.username)) {
					// setFormView('resend-phone-otp');
					dispatch(authActions.regPhone(data?.username));
					dispatch(authActions.regCountry(data?.userPhoneCountryCode));
				} else {
					navigate('/auth/login/resend-otp');
					dispatch(authActions.regEmail(data?.username));
				}
			} else if (error && error?.message_language_code === 'auth_0153') {
				navigate('/auth/login/authenticator');
				dispatch(authActions.regEmail(data?.username));
				dispatch(authActions.regPassword(data?.password));
			} else if (error && error?.action === 'not_verified_phone') {
				toast.error(error?.message, {
					position: toast.POSITION.TOP_RIGHT,
					theme: 'colored',
					autoClose: 6000,
				});
				// setFormView('resend-phone-otp');
				dispatch(authActions.regPhone(data?.username));
				dispatch(authActions.regPassword(data?.password));
				dispatch(authActions.regCountry(data?.userPhoneCountryCode));
			} else {
				errorHandler(error, true);
			}
		},
		onSuccess: (res: any) => {
			if (
				res?.data?.accessSecret === null ||
				res?.action === 'country_not_in_profile'
			) {
				dispatch(authActions.regAccessToken(res?.data?.accessToken));
				navigate('/auth/complete-registration');
			} else {
				localStorage.setItem('game-user-token', res?.data?.accessToken);
				localStorage.setItem('game-user-secret', res?.data?.accessSecret);
				if (res?.data?.user) {
					localStorage.setItem(
						'game-user-obj',
						JSON.stringify(res?.data?.user)
					);
				}
				successHandler(res, true);
				navigate('/play');
			}
		},
		retry: 0,
	});

	// Handles form submission for registration
	const onSubmit = () => {
		const formData = {
			msisdn: userPhone,
			password: password,
		};
		cleanInput(formData);
		setData({
			...data,
			username: userPhone,
			password: password,
			userPhoneCountryCode: 'NG',
		});
		mutate(formData);
	};

	return (
		<div className="lg:grid grid-cols-1 items-center gap-5 text-white px-0 sm:px-4">
			<div className="flex gap-2 items-center mb-8">
				<Back
					className="w-[34px] h-[34px] cursor-pointer"
					onClick={() => {
						navigate(-1);
						dispatch(authActions.regAdvocateUsername(''));
					}}
					data-testid="back-to-homepage"
				/>
				<h3 className="text-2xl font-medium">Create Password</h3>
			</div>
			<AuthFormAction>
				<div className="mb-10 flex items-start justify-between gap-4">
					<div>
						<p className="font-medium text-grey">Phone Number:</p>
						<p className="font-medium text-white">0{userPhone}</p>
					</div>
					<button
						type="button"
						onClick={() => {
							navigate(-1);
						}}
						className="font-medium text-green"
					>
						Edit Phone
					</button>
				</div>

				<h3 className="font-medium text-grey text-[15px] mb-6">
					Please create a strong password to protect your account
				</h3>

				<form>
					<TextInput
						label={'New password'}
						passwordInput={true}
						data-testid="signup-password"
						subLabel={<PasswordStrength password={password} />}
						onChange={(e) => {
							setPassword(e.target.value);
							dispatch(authActions.regPassword(e?.target?.value));
							if (
								e.target.value === confirmPassword &&
								e.target.value.length > 5
							) {
								setDisabled(false);
							} else {
								setDisabled(true);
							}
						}}
					/>

					<p className="mb-8 text-sm text-red">Minimum of 6 characters.</p>

					<TextInput
						passwordInput={true}
						label={'Confirm password'}
						onChange={(e) => handleChange(e)}
						data-testid="confirm-password"
					/>

					<Button
						text={'Continue'}
						type="button"
						disabled={disabled}
						loading={isLoading}
						data-testid="registration-button"
						className={
							'text-[15px] text-black font-medium btn-primary h-[56px] rounded-full mb-3 mt-8 justify-center block w-full px-8'
						}
						onClick={() => onSubmit()}
					/>
				</form>
			</AuthFormAction>
		</div>
	);
};
