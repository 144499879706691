import { useLocation, useNavigate } from 'react-router-dom';
import hammer_logo_2 from 'assets/icons/Hammer-logo-2.png';
import banner from 'assets/images/banner/prizes.png';
import banner1 from 'assets/images/banner/bg-1.png';
import banner2 from 'assets/images/banner/bg-2.png';
// import Title from 'assets/images/banner/5m.png';
import WinBg from 'assets/images/banner/win-cowries.png';

export const PromoPage3 = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const referral = queryParams.get('ref');

	return (
		<div
			className="w-full bg-[#EBF9D3] min-h-screen h-full md:pt-6 pt-3 pb-3 px-0 md:px-6 bg-bottom bg-no-repeat"
			style={{ backgroundImage: `url(${banner2})` }}
		>
			<div className="text-white p-4 mx-auto max-w-7xl">
				<div className="flex gap-2 justify-between mb-12 lg:mb-20">
					<img src={hammer_logo_2} alt="Logo" className="max-h-[64px] w-auto" />
					<button
						onClick={() => {
							navigate(`/auth/email-registration?ref=${referral}`);
						}}
						className="bg-transparent text-[#0F121D] border-[#85C60E] border-[2px] rounded-[5rem] lg:text-[20px] font-medium min-w-[250px] h-[54px] px-8 py-1 hidden lg:block"
					>
						Join the Draw
					</button>
				</div>

				<div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
					<div>
						<div className="pl-3 lg:pl-8">
							<p className="text-[#0F121D] text-lg lg:text-2xl font-medium mb-5">
								Win your first million in the{' '}
							</p>
							{/* <img src={Title} alt="Title" className="w-full max-w-[584px]" /> */}
							<div className="font-medium luckiest-guy-regular text-left">
								<h2 className="text-shadow-dark-1 text-[38px] lg:text-[48px] leading-[52px]">
									5 Million Cowries
								</h2>
								<h2 className="text-shadow-dark-2 text-[60px] lg:text-[74px] leading-[42px] lg:leading-[60px]">
									Mega Draw
								</h2>
							</div>
						</div>

						<div className="mt-12 lg:mt-16">
							<p className="pl-4 lg:pl-8 text-[#102B26] text-lg text-left font-medium">
								Be amongst the first 5 lucky players to
							</p>

							<img
								src={WinBg}
								alt="WinBg"
								className="w-full max-w-[350px] lg:max-w-[464px] mb-4"
							/>

							<p className="pl-4 lg:pl-8 text-[#102B26] text-lg text-left font-medium mb-2">
								(500,000 naira cash) each.
							</p>

							<div className="pl-4 lg:pl-8 flex items-center justify-center lg:justify-start w-full">
								<button
									onClick={() => {
										navigate(`/auth/email-registration?ref=${referral}`);
									}}
									className="w-full max-w-[460px] h-[64px] bg-[#84C70D] text-[#0F121D] border-[#F6FFE4AD] border-[6.4px] mt-8 rounded-[5rem] text-[20px] font-medium px-8 py-2"
								>
									Join the Draw!
								</button>
							</div>
						</div>
					</div>
					<div
						className="max-w-[540px] bg-cover bg-center bg-no-repeat"
						style={{ backgroundImage: `url(${banner1})` }}
					>
						<img src={banner} alt="banner" className="w-full mb-4" />
					</div>
				</div>
			</div>
		</div>
	);
};
