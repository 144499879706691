import { ReactNode } from 'react';

type ILayoutProps = {
	children: ReactNode;
	theme: string;
	toggleTheme: (e: string) => void;
};

export const HammerSpinLayout = ({ children }: ILayoutProps) => {
	return (
		<div className="bg-primary w-screen min-h-screen overflow-hidden grid">
			<main className="w-full h-full min-h-0 md:min-h-full overflow-hidden flex">
				<main className="w-full h-full overflow-y-auto">{children}</main>
			</main>
		</div>
	);
};
