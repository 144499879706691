import React, {
	ComponentProps,
	FC,
	PropsWithChildren,
	ReactNode,
	RefAttributes,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useContext } from 'react';
import {
	LayoutGroup,
	motion,
	MotionProps,
	useMotionValue,
} from 'framer-motion';
import debounce from 'utils/debounce';

type NotificationType = 'info' | 'warning' | 'success';
const NOTIFICATION_COLORS: Record<NotificationType, string> = {
	success: '#5AA108',
	info: '#FD7216',
	warning: '#F82D28',
};
type Notification = { id: string; text: string; level: NotificationType };
type addNotifications = (p: Omit<Notification, 'id'>) => void;
interface Context {
	addNotifications: addNotifications;
}
const NotificationContext = React.createContext<Context>({
	addNotifications: () => {},
});

interface Props extends PropsWithChildren {}
export default function NotificationProvider({ children }: Props) {
	const notificationRef = useRef<Record<string, NodeJS.Timeout>>({});
	const notificationsDispatch = useRef<addNotifications>(() => {});
	const [notifications, setNotifications] = useState<Notification[]>([]);
	const right = useMotionValue(0);
	const removeNotifications = useCallback(function __(_id: string) {
		setNotifications((prev) => prev.filter(({ id }) => id !== _id));
		clearTimeout(notificationRef.current?.[_id]);
	}, []);

	const addNotifications: addNotifications = useCallback(function _(item) {
		const id = (Math.random() * Date.now()).toString(36).slice(2, 9);
		setNotifications((prev) => [...prev, { id, ...item }]);
		notificationRef.current[id] = setTimeout(() => {
			removeNotifications(id);
		}, 3000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	notificationsDispatch.current = addNotifications;

	const removeOnClick = (id: string) => () => {
		removeNotifications(id);
	};

	useEffect(() => {
		const measure = debounce(() => {
			const container = document.getElementById('modal');
			if (container) {
				const { width, right: _r } = container.getBoundingClientRect();
				right.set(_r - width);
			}
		}, 250);

		measure();
		window.addEventListener('resize', measure);

		return () => {
			window.removeEventListener('resize', measure);
		};
	}, [right]);

	return (
		<>
			<LayoutGroup>
				<motion.div
					className="fixed top-[80px] z-[3000] flex flex-col gap-3"
					style={{ right }}
					layout
				>
					{notifications.map((item) => {
						return (
							<Wrapper
								key={item.id}
								onClose={() => {}}
								layout="position"
								style={{ backgroundColor: NOTIFICATION_COLORS[item.level] }}
								onClick={removeOnClick(item.id)}
								className="flex items-center gap-3 p-3 px-4 rounded-[30px]"
							>
								<SuccessIcon />
								<p className="font-inter font-semibold text-white">
									{item.text}
								</p>
							</Wrapper>
						);
					})}
				</motion.div>
			</LayoutGroup>
			<NotificationContext.Provider value={{ addNotifications }}>
				{children}
			</NotificationContext.Provider>
		</>
	);
}

interface NotificationWrapperProps
	extends Omit<ComponentProps<typeof motion.div>, 'children'>,
		MotionProps,
		RefAttributes<HTMLDivElement> {
	children: ReactNode;
	onClose(): void;
}

const Wrapper: FC<NotificationWrapperProps> = ({
	children,
	className,
	...props
}) => {
	const _className = `flex items-center' ${className} max-w-[400px]`;
	return (
		<motion.div className={_className} {...props}>
			{children}
		</motion.div>
	);
};

const SuccessIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_431_58600)">
				<path
					d="M18.3346 9.23355V10.0002C18.3336 11.7972 17.7517 13.5458 16.6757 14.9851C15.5998 16.4244 14.0874 17.4773 12.3641 17.9868C10.6408 18.4963 8.79902 18.4351 7.11336 17.8124C5.4277 17.1896 3.98851 16.0386 3.01044 14.5311C2.03236 13.0236 1.56779 11.2403 1.68603 9.44714C1.80427 7.65402 2.49897 5.94715 3.66654 4.58111C4.8341 3.21506 6.41196 2.26303 8.16479 1.867C9.91763 1.47097 11.7515 1.65216 13.393 2.38355"
					stroke="white"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M18.3333 3.33398L10 11.6757L7.5 9.17565"
					stroke="white"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_431_58600">
					<rect width="20" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
export const useAddNotification = () =>
	useContext(NotificationContext).addNotifications;
