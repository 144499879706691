import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button3d } from 'components/button/Button';
import Image from 'assets/onboarding/onboardImage5.png';
import Image2 from 'assets/onboarding/onboardImage6.png';
import { TelegramBotModal } from 'components/modals/TelegramBotModal';
import { CreateUsernameModal } from 'components/modals/account/CreateUsername';

// interface Prop {
// 	setOnboardingView: (e: string) => void;
// }

export function Onboarding3() {
	const { t } = useTranslation('common');
	const [modal, setModal] = useState({ isOpen: false, type: '', modalObj: {} });
	const modalOpen = (type: string, modalObj?: any) =>
		setModal({ isOpen: true, type: type, modalObj });
	const modalClose = (e?: boolean) =>
		setModal({ isOpen: e || false, type: '', modalObj: {} });

	return (
		<div className="relative w-full h-screen overflow-hidden connect-background">
			<div className="relative py-6 h-full overflow-scroll">
				<img
					src={Image}
					alt="Image"
					className="absolute top-[8%] left-8 h-[26%]"
				/>

				<div className="flex flex-col h-full w-full max-w-[320px] mx-auto gap-4 justify-center items-center">
					<div>
						<p className="text-primary text-[2.5rem] leading-tight font-medium text-center">
							{t('onboard.connectWith')}
						</p>
						<p className="text-primary text-[2.5rem] leading-tight font-medium text-center">
							{t('onboard.friends')}
						</p>
					</div>

					<div className="flex flex-col w-full gap-2">
						<p className="text-primary text-[1rem] font-semibold text-center">
							{t('onboard.referYourFriends')}
						</p>
					</div>

					<div className="flex items-center justify-center gap-2">
						<div className="w-[8px] h-[8px] rounded-full bg-[#FFFFFF5E]" />
						<div className="w-[8px] h-[8px] rounded-full bg-[#FFFFFF5E]" />
						<div className="w-[8px] h-[8px] rounded-full bg-[#84C70D] outline outline-[#84C70D] outline-offset-2 ml-1" />
					</div>
				</div>

				<img
					src={Image2}
					alt="Image"
					className="absolute bottom-0 right-0 h-[43%] object-contain"
				/>
			</div>
			<div className="absolute bottom-12 left-0 right-0 z-10 w-full px-4">
				<Button3d
					text={'Get Started'}
					disabled={false}
					onClick={() => {
						modalOpen('username');
					}}
					className={`font-semibold capitalize w-full flex items-center justify-center h-[56px] mb-5`}
				/>
			</div>
			{modal.isOpen && modal.type === 'username' && (
				<TelegramBotModal
					isOpen={modal.isOpen}
					modalClose={modalClose}
					closeIcon={true}
					modalChild={<CreateUsernameModal />}
				/>
			)}
		</div>
	);
}
