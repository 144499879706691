import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInitialState {
	email: string;
	password: string;
	countryCode: string;
	userPhone: string;
	otp: string;
	advocateUserName: string;
	type: string;
	accessToken: string;
}

export const initialState: IInitialState = {
	email: '',
	password: '',
	advocateUserName: '',
	countryCode: '',
	userPhone: '',
	otp: '',
	type: '',
	accessToken: '',
};

export const authSlice = createSlice({
	name: 'hammer-games',
	initialState,
	reducers: {
		regEmail: (state, action: PayloadAction<string>) => {
			state.email = action.payload;
		},
		regPassword: (state, action: PayloadAction<string>) => {
			state.password = action.payload;
		},
		regCountry: (state, action: PayloadAction<string>) => {
			state.countryCode = action.payload;
		},
		regPhone: (state, action: PayloadAction<string>) => {
			state.userPhone = action.payload;
		},
		regOtp: (state, action: PayloadAction<string>) => {
			state.otp = action.payload;
		},
		regAdvocateUsername: (state, action: PayloadAction<string>) => {
			state.advocateUserName = action.payload;
		},
		regUserType: (state, action: PayloadAction<string>) => {
			state.type = action.payload;
		},
		regAccessToken: (state, action: PayloadAction<string>) => {
			state.accessToken = action.payload;
		},
	},
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
