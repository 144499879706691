import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInitialState {
	wheelSlices: object;
	powerUps: string[];
	spinAmount: number;
	winSection: string;
}

export const initialState: IInitialState = {
	wheelSlices: {},
	powerUps: [],
	spinAmount: 0,
	winSection: '',
};

export const wheelSpinnerSlice = createSlice({
	name: 'hammer-games',
	initialState,
	reducers: {
		setWheelSlices: (state, action: PayloadAction<object>) => {
			state.wheelSlices = action.payload;
		},
		setPowerUps: (state, action: PayloadAction<string[]>) => {
			state.powerUps = action.payload;
		},
		setSpinAmount: (state, action: PayloadAction<number>) => {
			state.spinAmount = action.payload;
		},
		setWinningSection: (state, action: PayloadAction<string>) => {
			state.winSection = action.payload;
		},
	},
});

export const wheelSpinnerActions = wheelSpinnerSlice.actions;
export const wheelSpinnerReducer = wheelSpinnerSlice.reducer;
