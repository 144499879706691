import { backIcon } from 'assets';
import { Button } from 'components/button/Button';
import { useNavigate } from 'react-router-dom';
import { DailyBonus } from './DailyBonus';
import { useTranslation } from 'react-i18next';

export const DailyBonusPage = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('common');

	return (
		<div className="w-full py-6 px-[3%]">
			<div className="w-full md:w-[44rem] h-full lg:ml-[10vw] text-primary">
				<div className="top w-full relative flex items-center mb-8">
					<Button
						text="Back"
						icon={backIcon}
						onClick={() => navigate(-1)}
						className="bg-secondary absolute px-3 md:px-5 py-1 md:py-2 text-[0.8rem] md:text-[1.176rem] font-normal text-secondary hidden md:flex items-center justify-center rounded-[2.27rem]"
					/>
					<Button
						text=""
						icon={backIcon}
						onClick={() => navigate(-1)}
						className="bg-secondary absolute px-3 md:px-5 py-1 md:py-2 text-[0.8rem] md:text-[1.176rem] font-normal text-secondary flex md:hidden items-center justify-center rounded-[2.27rem]"
					/>
					<span className="text-[1rem] md:text-[1.75rem] relative font-medium text-primary text-center mx-auto">
						{t('util.dailyBonus')}
					</span>
				</div>

				<DailyBonus open={false} setOpen={() => {}} isAPage={true} />
			</div>
		</div>
	);
};
