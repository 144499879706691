interface Props {
	children: string;
}

interface ActionProps {
	children: React.ReactNode;
}

export const AuthFormTitle = ({ children }: Props) => {
	return (
		<div className="hidden sm:block lg:max-w-[415px] md:text-center lg:text-left py-8 md:py-8 sm:py-0 sm:mb-0">
			<h2 className="text-[36px] sm:text-4xl md:text-5xl font-medium leading-10 sm:leading-normal">
				{children}
			</h2>
		</div>
	);
};

export const AuthFormAction = ({ children }: ActionProps) => {
	return (
		<div className="rounded-[13px] bg-secondary px-3 sm:px-5 sm:px-8 py-8">
			{children}
		</div>
	);
};
