import axios, {
	AxiosError,
	AxiosResponse,
	InternalAxiosRequestConfig,
} from 'axios';
import { _getSecret, _getToken, _getUser } from 'utils';
import { hmacAuth } from './HmacSetup';
import { autoLoginApi, logOutUser } from './apiRequest';
import { toast } from 'react-toastify';

interface IPostProps {
	url: string;
	payload?: object;
}

export const apiService = (requestData?: any) => {
	const baseUrl = process.env.REACT_APP_HAMMER_GAMES_STAGING_API;
	const merchantDomain = process.env.REACT_APP_HAMMER_GAMES_DOMAIN;

	const user = _getUser();

	const service = axios.create({
		baseURL: `${baseUrl}/api`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Domain: merchantDomain,
			Authorization: 'Basic parole',
		},
	});

	service.interceptors.request.use((config: InternalAxiosRequestConfig) => {
		const token = _getToken();
		const secret = _getSecret();
		const { hashBase64, timestamp } = hmacAuth(token, secret, requestData);

		if (!token) return config;
		config.headers!['Authentication'] = 'Token ' + token;
		config.headers!['apisignature'] = hashBase64;
		config.headers!['timestamp'] = timestamp;
		return config;
	});

	// Create a Function to Refresh Token
	const refreshToken = async () => {
		const secret = _getSecret();
		if (secret !== null || secret !== undefined) {
			const response = await autoLoginApi({
				email: user.userEmail,
				accessSecret: secret,
			});

			// Assuming the server returns a new access token
			const newAccessToken = response?.data?.data?.accessToken;

			// Update the authentication state with the new access token
			// You may also need to update the expiration time for the token
			// and store it securely in a cookie or local storage
			localStorage.setItem('game-user-token', newAccessToken);

			return newAccessToken;
		}
	};

	service.interceptors.response.use(
		(response: AxiosResponse) => {
			return response?.data;
		},
		async (error: AxiosError) => {
			const errors: any = error?.response?.data;

			// check for api signature failure
			if (error?.response && errors?.action === 'auto_login') {
				// Session has timed out
				console.log('auto login error');

				// Handle auto-login logic here
				try {
					const newAccessToken = await refreshToken();
					// Retry the original request with the new token
					console.log('New Token: ', newAccessToken);
					// const config = error?.config;
					// config!.headers['Authorization'] = `Bearer ${newAccessToken}`;
					//return axios.request(config!);
				} catch (refreshError) {
					// Handle refresh token failure (e.g., log out the user)
					console.log(refreshError);
					logOutUser();
					//return Promise.reject(refreshError);
				}
			}

			if (errors?.action === 'please_login') {
				logOutUser();
				console.log('please_login');
			}

			if (errors?.action === 'signature_failure') {
				toast.error(
					'Signature failure, please login again or contact support if this persists.',
					{
						position: toast.POSITION.TOP_RIGHT,
						theme: 'colored',
						autoClose: 6000,
					}
				);
				logOutUser();
			}

			console.log(error);

			return Promise.reject(errors);
		}
	);

	return {
		get: async (url: string) => {
			try {
				const data = service.get(url);
				const resolvedData = await Promise.resolve(data);
				return resolvedData;
			} catch (error: any) {
				return Promise.reject(error);
			}
		},

		post: async ({ url, payload }: IPostProps) => {
			try {
				const data = service.post(url, payload);
				const resolvedData = await Promise.resolve(data);
				return resolvedData;
			} catch (error) {
				return Promise.reject(error);
			}
		},

		patch: async ({ url, payload }: IPostProps) => {
			try {
				const data = service.patch(url, payload);
				const resolvedData = await Promise.resolve(data);
				return resolvedData;
			} catch (error) {
				return Promise.reject(error);
			}
		},

		delete: async ({ url, payload }: IPostProps) => {
			try {
				const data = service.delete(url, payload);
				const resolvedData = await Promise.resolve(data);
				return resolvedData;
			} catch (error) {
				return Promise.reject(error);
			}
		},

		put: async ({ url, payload }: IPostProps) => {
			try {
				const data = service.put(url, payload);
				const resolvedData = await Promise.resolve(data);
				return resolvedData;
			} catch (error) {
				return Promise.reject(error);
			}
		},
	};
};

// export const apiService = apiResource();
