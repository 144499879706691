import { HmacSHA256, enc } from 'crypto-js';
interface iHmacAuth {
	timestamp: string;
	token: string;
	hashBase64: string;
}

export function hmacAuth(
	token: string,
	accessSecret: string,
	body?: any
): iHmacAuth {
	const timestamp: any = new Date().getTime();

	// Trim white spaces in values of the body object
	const trimmedBody: Record<string, string | number | undefined> = {};
	for (const key in body) {
		if (Object.prototype.hasOwnProperty.call(body, key)) {
			const value = body[key];
			trimmedBody[key] = typeof value === 'string' ? value.trim() : value;
		}
	}

	const payload = new URLSearchParams({
		...trimmedBody,
		timestamp: String(timestamp),
		'api-key': token,
		'api-secret': accessSecret,
	}).toString();

	const hashBase64 = HmacSHA256(payload, accessSecret || '').toString(enc.Hex);
	return { hashBase64, timestamp, token };
}
