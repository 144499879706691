import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import successHandler from 'handlers/successHandler';
import errorHandler from 'handlers/errorHandler';
import { mobileLoginViaAutoLogin } from 'api/apiRequest';
import CircularLoader from 'components/loader/CircularLoader';

export const MobileLogin = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const queryParams = new URLSearchParams(location.search);
	const memberId = queryParams.get('memberId');
	const accessSecret = queryParams.get('accessSecret');

	useEffect(() => {
		const userData = {
			accessSecret: accessSecret,
			memberId: memberId,
		};
		console.log('trying to do external login');
		mutate(userData);
	}, []);

	const { isLoading, mutate } = useMutation(mobileLoginViaAutoLogin, {
		onError: (error: any) => {
			errorHandler(error, true);
		},
		onSuccess: (res: any) => {
			localStorage.setItem('game-user-token', res?.data?.accessToken);
			localStorage.setItem('game-user-secret', res?.data?.accessSecret);
			if (res?.data?.user) {
				localStorage.setItem('game-user-obj', JSON.stringify(res?.data?.user));
			}
			successHandler(res, true);
			navigate('/play');
		},
		retry: 0,
	});

	return (
		<>
			{isLoading ? (
				<div className="h-full  w-full flex items-center justify-center">
					<CircularLoader />
				</div>
			) : (
				<div className="h-full  w-full flex items-center justify-center">
					<CircularLoader />
				</div>
			)}
		</>
	);
};
