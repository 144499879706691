import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import TonBanner from 'assets/images/advert-ton.png';
// import ReferBanner from 'assets/images/advert-referral.png';
import { _isAnEmptyObject } from 'utils';
// import freespinBanner from 'assets/images/free-spin-banner.svg';

export const Carousel = ({
	user,
	setOpen,
	isMobile,
	navigateToGameRoute,
}: any) => {
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const Slider: any = require('react-slick').default;

	const desktopSettings = {
		infinite: true,
		speed: 2000,
		slidesToShow: 3,
		slidesToScroll: 1,
		dots: true,
		autoplay: false,
		autoplaySpeed: 1000,
		cssEase: 'linear',
	};

	const mobileSettings = {
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		dots: false,
		centerMode: true,
		centerPadding: '4%',
		autoplay: true,
		autoplaySpeed: 4000,
	};

	const settings = isMobile ? mobileSettings : desktopSettings;

	return (
		<>
			<Slider {...settings}>
				<img
					src={TonBanner}
					alt=""
					className="rounded-[8px] w-full md:w-[49%] h-[3.2rem] object-cover cursor-pointer px-1 "
					onClick={() =>
						!_isAnEmptyObject(user)
							? navigateToGameRoute('games') //navigateToGameRoute('odogwu-hunter')
							: setOpen(true)
					}
				/>
				{/* <img
					src={ReferBanner}
					alt=""
					className="rounded-[8px] w-full md:w-[49%] h-[3.2rem] object-cover px-1 cursor-pointer"
					onClick={() =>
						!_isAnEmptyObject(user)
							? navigateToGameRoute('account/referrals')
							: setOpen(true)
					}
				/> */}

				{/* <div
					className="px-1 w-full md:w-[49%] h-[3.2rem] object-cover cursor-pointer"
					onClick={() =>
						!_isAnEmptyObject(user)
							? navigateToGameRoute('hammer-spin/free-spin', {
									state: {
										free: true,
									},
							  })
							: setOpen(true)
					}
				>
					<div
						style={{
							backgroundImage: `url(${freespinBanner})`,
							width: '100%',
							height: '100%',
							backgroundSize: 'cover',
							backgroundPosition: '50%',
							borderRadius: '10px',
						}}
					/>
				</div> */}
			</Slider>
		</>
	);
};
