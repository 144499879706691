import { useEffect, useState } from 'react';
import { tick_icon, hammer_logo_2, arrowLeft } from 'assets';
import { Button3d } from 'components/button/Button';
import China from 'assets/countries/china.svg';
import France from 'assets/countries/france.svg';
import Italy from 'assets/countries/italy.svg';
import Japan from 'assets/countries/japan.svg';
import Spain from 'assets/countries/spain.svg';
import Russia from 'assets/countries/russia.svg';
import Germany from 'assets/countries/germany.svg';
import Portugal from 'assets/countries/portugal.svg';
import Korea from 'assets/countries/s-korea.svg';
import UnitedKingdom from 'assets/countries/united-kingdom.svg';
import { useNavigate, useLocation } from 'react-router-dom';

interface Prop {
	setOnboardingView?: (e: string) => void;
	source?: string;
	setIsLanguageChange?: (e: boolean) => void;
	isLanguageChange?: boolean;
}

export function SelectLanguage({
	setOnboardingView,
	setIsLanguageChange,
	isLanguageChange,
	source,
}: Prop) {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const [language, setLanguage] = useState('');
	const [currentLang, setCurrentLang] = useState('');

	useEffect(() => {
		const activeLang = localStorage.getItem('game-user-lang');
		activeLang && setCurrentLang(activeLang);
	}, [currentLang]);

	const handleSelect = (lang: string) => {
		setLanguage(lang);
		localStorage.setItem('game-user-lang', lang);
	};

	return (
		<div
			className={`relative w-full ${
				source === 'settings' ? 'h-[78vh]' : 'h-screen '
			} px-4 language-background overflow-hidden  box-border`}
		>
			<div className="relative py-4 h-full">
				{source === 'settings' && (
					<div
						onClick={() => navigate(-1)}
						className="bg-[#FFFFFF0A] border border-[#FFFFFF14] w-[3rem] rounded-[30px] lg:px-3 px-4 py-1 flex items-center gap-2 cursor-pointer"
					>
						<img src={arrowLeft} className="w-8 h-5" alt="left" />
					</div>
				)}
				<div className="flex flex-col w-full gap-3 justify-center items-center mb-8">
					<img src={hammer_logo_2} alt="gameplay" className="h-[56px] w-auto" />
					<p className="text-primary text-[1.5rem] font-medium">
						Select Your Language
					</p>
				</div>

				<div
					className={`flex flex-col w-full ${
						source === 'settings' ? 'h-[50vh]' : 'h-[75vh] '
					}  overflow-y-scroll gap-3 pb-20 pr-2 box-border`}
				>
					{lang_options?.length > 0 &&
						lang_options.map((lang, index) => (
							<div
								key={index}
								onClick={() => handleSelect(lang?.key)}
								className={`border ${
									lang.key === language
										? 'border-[#84C70D]'
										: // : currentLang === lang.key
											// ? 'border-[#e0f5bb]'
											'border-[#FFFFFF14]'
								} flex items-center gap-4 w-full bg-[#FFFFFF0A] py-1 rounded-[12px] px-2`}
							>
								<img
									className="flex-none h-[3rem] w-[3rem] rounded-[8px] object-cover"
									alt="icon"
									src={lang?.icon}
								/>
								<div className="flex items-center justify-between gap-3 w-full">
									<span
										className={`${
											lang.key === language ? 'text-green' : 'text-primary'
										} text-[1.25rem] truncate`}
									>
										{lang?.name}
									</span>
									{lang.key === language && (
										<img
											src={tick_icon}
											alt="tick"
											className="h-[15px] w-[15px] flex-none"
										/>
									)}
								</div>
							</div>
						))}
				</div>
			</div>
			<div className="absolute bottom-4 left-0 right-0 z-[150] w-full px-4">
				<Button3d
					text="Continue"
					disabled={language === ''}
					onClick={() => {
						if (pathname === '/account/settings/language' && language !== '') {
							setIsLanguageChange && setIsLanguageChange(!isLanguageChange);
							navigate('/account/settings');
						} else {
							language !== '' &&
								setOnboardingView &&
								setOnboardingView('welcome');
							setIsLanguageChange && setIsLanguageChange(!isLanguageChange);
						}
					}}
					className={`font-semibold w-full flex items-center justify-center h-[3rem] ${
						source === 'settings' ? 'mb-1' : 'mb-4'
					}`}
				/>
			</div>
		</div>
	);
}

const lang_options = [
	{ name: 'English', icon: UnitedKingdom, key: 'en' },
	{ name: '中文 - Chinese', icon: China, key: 'zh' },
	{ name: 'Español - Spanish', icon: Spain, key: 'es' },
	{ name: '日本語 - Japanese', icon: Japan, key: 'ja' },
	{ name: 'Deutsch - German', icon: Germany, key: 'de' },
	{ name: 'Français - French', icon: France, key: 'fr' },
	{ name: 'Português - Portuguese', icon: Portugal, key: 'pt' },
	{ name: 'Русский - Russian', icon: Russia, key: 'ru' },
	{ name: '한국어 - Korean', icon: Korea, key: 'ko' },
	{ name: 'Italiano - Italian', icon: Italy, key: 'it' },
];
