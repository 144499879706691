import { configureStore } from '@reduxjs/toolkit';
import { gamesReducer } from './slices';
import { authReducer } from './slices/authSlice';
// import { accountReducer } from './slices/accountSlice';
import { transactionsReducer } from './slices/transactions/transactionsActions';
import { gameCenterReducer } from './slices/gameCenter/gameCenterActions';
import { paymentReducer } from './slices/payments/paymentActions';
import { accountReducer } from './slices/account/accountSlice';
import { userReducer } from './slices/user/userSlice';
import { profileReducer } from './slices/profile/profileActions';
import { spinSettingsReducer } from './slices/spinSettings/spinSettingsActions';
import { wheelSpinnerReducer } from './slices/wheelSpinner/wheelSpinnerActions';
import { publicReducer } from './slices/public/publicActions';
import { odogwuReducer } from './slices/odogwu/odogwuSlice';
import { chatReducer } from './slices/chat/chatSlice';

export const getStore = configureStore({
	reducer: {
		gamesReducer: gamesReducer,
		authReducer: authReducer,
		accountReducer: accountReducer,
		transactionsReducer: transactionsReducer,
		gameCenterReducer: gameCenterReducer,
		paymentReducer: paymentReducer,
		userReducer: userReducer,
		profileReducer: profileReducer,
		spinSettingsReducer: spinSettingsReducer,
		wheelSpinnerReducer: wheelSpinnerReducer,
		publicReducer: publicReducer,
		odogwuReducer: odogwuReducer,
		chatReducer: chatReducer,
	},
});

// export type RootState = ReturnType<typeof getStore.getState>;
// export type AppDispatch = typeof getStore.dispatch;
