import './CircularLoader.css';

interface IProps {
	color?: string;
	bg?: string;
}

const CircularLoader = ({ bg, color }: IProps) => {
	return (
		<div className="loader-wrapper">
			<div
				className="loader border-t border[#69d08d]"
				style={{
					border: `8px solid ${bg ? bg : '#f3f3f3'}`,
					borderTop: `8px solid ${color ? color : '#69d08d'}`,
				}}
			></div>
		</div>
	);
};

export default CircularLoader;
