import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInitialState {
	profile: object;
}

export const initialState: IInitialState = {
	profile: {},
};

export const profileSlice = createSlice({
	name: 'hammer-games',
	initialState,
	reducers: {
		getProfile: (state, action: PayloadAction<object>) => {
			//console.log('hit', action.payload);
			state.profile = action.payload;
		},
	},
});

export const profileActions = profileSlice.actions;
export const profileReducer = profileSlice.reducer;
