import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';
import { getSocialConfiguration } from 'api/apiRequest';

export const bscMainnet = {
	chainId: 56,
	name: 'Binance Chain',
	currency: 'BSC',
	explorerUrl: 'https://bscscan.com',
	rpcUrl: 'https://bsc-dataseed1.defibit.io/',
};

export const bscTestnet = {
	chainId: 97,
	name: 'Binance Chain [TESTNET]',
	currency: 'BSC',
	explorerUrl: 'https://testnet.bscscan.com',
	rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
};

const metadata = {
	name: 'Hammer Games',
	description: 'Play Games',
	url: 'https://hammergames.com',
	icons: ['https://hammergames.com/favicon.ico'],
};

const ethersConfig = defaultConfig({
	metadata,
	enableEIP6963: true,
	enableInjected: true,
});

export default async () => {
	const info = await getSocialConfiguration();

	const walletConnectProjectId = info?.data?.walletConnectProjectId;

	try {
		if (walletConnectProjectId) {
			createWeb3Modal({
				ethersConfig,
				chains: [bscMainnet],
				projectId: walletConnectProjectId,
				enableAnalytics: true,
				featuredWalletIds: [
					'4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
					'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
				],
			});
		}
	} catch (e) {
		void 0; // Intentionally ignoring the error
	}
};

export const usdtAbi = [
	{ inputs: [], stateMutability: 'nonpayable', type: 'constructor' },
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'owner',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'spender',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'value',
				type: 'uint256',
			},
		],
		name: 'Approval',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: true, internalType: 'address', name: 'from', type: 'address' },
			{ indexed: true, internalType: 'address', name: 'to', type: 'address' },
			{
				indexed: false,
				internalType: 'uint256',
				name: 'value',
				type: 'uint256',
			},
		],
		name: 'Transfer',
		type: 'event',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'owner', type: 'address' },
			{ internalType: 'address', name: 'spender', type: 'address' },
		],
		name: 'allowance',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'spender', type: 'address' },
			{ internalType: 'uint256', name: 'amount', type: 'uint256' },
		],
		name: 'approve',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'recipient', type: 'address' },
			{ internalType: 'uint256', name: 'amount', type: 'uint256' },
		],
		name: 'transfer',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'nonpayable',
		type: 'function',
	},
];
