/* eslint-disable @typescript-eslint/no-empty-function */
import { Fragment, ReactNode, FC } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ReactComponent as Close } from '../../assets/icons/close.svg';

type Props = {
	children: ReactNode;
	open: boolean;
	bgColor: string;
	width: string;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	noSpacing?: boolean;
	showCloseBtn?: boolean;
	widthFit?: boolean;
};

export const ModalLayout: FC<Props> = ({
	children,
	open,
	setOpen,
	width,
	bgColor,
	noSpacing,
	showCloseBtn = true,
	widthFit,
}) => {
	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div
						className={`flex items-center justify-center min-h-full text-center sm:p-0`}
					>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel
								as="div"
								onClick={(e: any) => e.stopPropagation()}
								className={`${bgColor} relative rounded-xl ${
									noSpacing ? `` : 'px-4 py-5 pb-4 sm:my-8'
								} text-left overflow-hidden shadow-xl transform transition-all ${
									widthFit ? 'w-fit' : 'w-full'
								}  max-w-[${width ? width : '482px'}] `}
							>
								{showCloseBtn && (
									<button
										type="button"
										onClick={() => setOpen(false)}
										className="absolute flex items-center justify-center top-6 right-5 sm:right-6 h-[36px] sm:h-[56px] w-[36px] sm:w-[56px] rounded-full bg-primary"
									>
										<Close className="h-[21px] sm:h-[34px] w-[21px] sm:w-[34px]" />
									</button>
								)}

								<div>{children}</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};
