import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Determine response of handler
 * @param {string} alternateMessage
 * @param {object} data
 * @returns {string}
 */
const handlerResponse = (alternateMessage, data = {}) => {
	if (data.data && data.data.message) {
		return data.data.message;
	}
	return alternateMessage;
};

/**
 * Parse success response from server
 * @param {*} successObject
 * @param {boolean} shouldDispatchAlert
 * @returns {string}
 */
const handler = (successObject, shouldDispatchAlert = false) => {
	try {
		if (successObject.data) {
			if (successObject.data.message) {
				// Send found success message to alert
				shouldDispatchAlert &&
					toast.success(successObject?.data?.message, {
						position: toast.POSITION.TOP_RIGHT,
						theme: 'colored',
					});
			} else {
				// Send generic success message to alert
				shouldDispatchAlert &&
					toast.success('Success', {
						position: toast.POSITION.TOP_RIGHT,
						theme: 'colored',
					});
			}

			// Return a response
			return handlerResponse('Request was successful!', successObject);
		} else if (successObject.message) {
			// Send found success message to alert
			shouldDispatchAlert &&
				toast.success(successObject?.message, {
					position: toast.POSITION.TOP_RIGHT,
					theme: 'colored',
				});

			// Return a response
			return handlerResponse('Request was successful!', successObject);
		} else {
			// Send generic info message to alert
			shouldDispatchAlert &&
				toast.info('Application is Unavailable', {
					position: toast.POSITION.TOP_RIGHT,
					theme: 'colored',
				});

			// Return a response
			return handlerResponse('Try again', successObject);
		}
	} catch (error) {
		console.log(
			'Success Handler System Failure - Error Experienced In Processing Success Object',
			error
		);
	}
};

export default handler;
