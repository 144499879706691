import { useNavigate } from 'react-router-dom';
import {
	CircularProgressbarWithChildren,
	buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import OdogwuUser from 'assets/images/odogwu-user.png';

interface Prop {
	user?: any;
	isActiveSubExist?: any;
	userProfileData?: any;
	getPercentage: () => number;
	modalOpen: (e: string) => void;
}

export const UserLevel = ({
	userProfileData,
	user,
	// isActiveSubExist,
	getPercentage, // modalOpen,
}: Prop) => {
	const navigate = useNavigate();

	return (
		<div className="px-5 py-1 h-[40px] flex items-center justify-between gap-3 bg-[#FFFFFF0A] border border-[#FFFFFF14] rounded-[12px] relative flex-none">
			{user !== null && (
				<div className="absolute -bottom-1 -left-4 w-fit">
					<div
						onClick={() => navigate('/account')}
						className="profile flex items-center gap-1 cursor-pointer relative "
					>
						<>
							<CircularProgressbarWithChildren
								value={isNaN(getPercentage()) ? 0 : getPercentage()}
								counterClockwise={true}
								className="w-[42px] h-[42px] relative"
								styles={buildStyles({
									backgroundColor: 'green',
									trailColor: '#2A2D37',
									pathColor: '#FF8B3E',
								})}
							>
								<img
									style={{ width: '34px', height: '34px' }}
									src={OdogwuUser}
									className="rounded-full bg-[#1D202B]"
									alt="avatar"
								/>
							</CircularProgressbarWithChildren>
						</>
					</div>
				</div>
			)}

			<div className="font-medium pl-5">
				<p className="text-primary text-[0.875rem]">
					Level {userProfileData?.data?.gemsEconomy?.member?.currentLevel}{' '}
				</p>
			</div>

			{/* <div className="text-white">
				<button
					type="button"
					onClick={() => navigate('/subscriptions')}
					className="flex gap-1 items-center justify-center bg-[#FFFFFF1F] px-3 py-1 rounded-[36px] h-[2rem] min-w-[90px]"
				>
					{isActiveSubExist ? (
						<>
						
							<div className="text-[0.81rem] text-white">
								{isActiveSubExist?.subscriptionPlanId?.title
									.toLowerCase()
									.includes('premium') && (
									<div className="flex items-center gap-1">
										<img
											src={premium_sub}
											className="h-[18px] w-[18px]"
											alt="icon"
										/>
										<p className="text-[0.81rem] text-white">Premium</p>
									</div>
								)}
								{isActiveSubExist?.subscriptionPlanId?.title
									.toLowerCase()
									.includes('basic') && (
									<div className="flex items-center gap-1">
										<img
											src={basic_sub}
											className="h-[18px] w-[18px]"
											alt="icon"
										/>
										<p className="text-[0.81rem] text-white">Basic</p>
									</div>
								)}
							</div>
						</>
					) : (
						<div className="flex items-center gap-1">
							<img src={free_sub} className="h-[18px] w-[18px]" alt="icon" />
							<p className="text-[0.81rem] text-white">Free</p>
						</div>
					)}
				</button>
			</div> */}

			{/* {modal.isOpen && modal.type === 'subscriptions' && (
				<TelegramBotModal
					isOpen={modal.isOpen}
					modalClose={modalClose}
					closeIcon={true}
					modalChild={
						<UserSubscriptionsModal isActiveSubExist={isActiveSubExist} />
					}
				/>
			)} */}
		</div>
	);
};
