import axios, { AxiosError, AxiosResponse } from 'axios';
// import { baseUrl, merchantDomain } from './axios';

interface IPostProps {
	url: string;
	payload?: object | string | any;
}

// apiResource
const publicService = () => {
	const baseURL = process.env.REACT_APP_HAMMER_GAMES_STAGING_API;
	const merchantDomain = process.env.REACT_APP_HAMMER_GAMES_DOMAIN;

	const service = axios.create({
		baseURL: `${baseURL}/api`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Domain: merchantDomain,
			Authorization: 'Basic parole',
			countryCode: 'ng',
		},
	});

	service.interceptors.response.use(
		(response: AxiosResponse) => {
			return response?.data;
		},
		(error: AxiosError) => {
			const errors = error?.response?.data;

			return Promise.reject(errors);
		}
	);

	return {
		get: async (url: string) => {
			try {
				const data = service.get(url);
				const resolvedData = await Promise.resolve(data);
				return resolvedData;
			} catch (error) {
				return Promise.reject(error);
			}
		},

		post: async ({ url, payload }: IPostProps) => {
			try {
				const data = service.post(url, payload);
				const resolvedData = await Promise.resolve(data);
				return resolvedData;
			} catch (error) {
				return Promise.reject(error);
			}
		},

		patch: async ({ url, payload }: IPostProps) => {
			try {
				const data = service.patch(url, payload);
				const resolvedData = await Promise.resolve(data);
				return resolvedData;
			} catch (error) {
				return Promise.reject(error);
			}
		},

		delete: async ({ url, payload }: IPostProps) => {
			try {
				const data = service.delete(url, payload);
				const resolvedData = await Promise.resolve(data);
				return resolvedData;
			} catch (error) {
				return Promise.reject(error);
			}
		},

		put: async ({ url, payload }: IPostProps) => {
			try {
				const data = service.put(url, payload);
				const resolvedData = await Promise.resolve(data);
				return resolvedData;
			} catch (error) {
				return Promise.reject(error);
			}
		},
	};
};

export const publicApiService = publicService();
