import SoundPlayer from 'components/sounds/GameSounds';
import { toast } from 'react-toastify';

/**
 * Determine response of handler
 * @param {string} alternateMessage
 * @param {object} data
 * @returns {string}
 */
const handlerResponse = (alternateMessage, data = {}) => {
	if (data.data && data.data.message) {
		return data.data.message;
	}
	return alternateMessage;
};

/**
 * Parse error response from server
 * @param {*} errorObject
 * @param {boolean} shouldDispatchAlert
 * @returns {string}
 */
const handler = (errorObject, shouldDispatchAlert = false) => {
	console.log('hit', errorObject);
	try {
		if (errorObject.response && errorObject.response.data) {
			let errors = errorObject?.response?.data?.errors;
			let message = errorObject?.response?.data?.message;

			if (errors) {
				// Map through errors
				Object.values(errors).map((item) => {
					// Send found errors to alert
					SoundPlayer.playSound('error2');
					return (
						shouldDispatchAlert &&
						toast.warning(item.toString(), {
							position: toast.POSITION.TOP_RIGHT,
							theme: 'colored',
							autoClose: 6000,
						})
					);
				});
			} else if (message && typeof message === 'object') {
				// Map through messages
				Object.values(message).map((item) => {
					// Send found messages to alert
					SoundPlayer.playSound('error2');
					return (
						shouldDispatchAlert &&
						toast.warning(item.toString(), {
							position: toast.POSITION.TOP_RIGHT,
							theme: 'colored',
							autoClose: 6000,
						})
					);
				});
			} else if (message && typeof message === 'string') {
				// Send generic error message to alert
				SoundPlayer.playSound('error2');
				shouldDispatchAlert &&
					toast.warning(message, {
						position: toast.POSITION.TOP_RIGHT,
						theme: 'colored',
						autoClose: 6000,
					});

				// Return a response
				return handlerResponse('Something went wrong!', errorObject.response);
			} else {
				// Send generic error message to alert
				SoundPlayer.playSound('error2');
				shouldDispatchAlert &&
					toast.warning('Something went wrong! Please try again', {
						position: toast.POSITION.TOP_RIGHT,
						theme: 'colored',
						autoClose: 6000,
					});
			}
			// Return a response
			return handlerResponse('Something went wrong!', errorObject.response);
		} else if (errorObject.request) {
			// No network connection
			if (errorObject.message === 'Network Error') {
				// Send found errors to alert
				SoundPlayer.playSound('error3');
				shouldDispatchAlert &&
					toast.error('Network is Unavailable', {
						position: toast.POSITION.TOP_RIGHT,
						theme: 'colored',
					});
			} else {
				// Send generic error message to alert
				SoundPlayer.playSound('error1');
				shouldDispatchAlert &&
					toast.error('Session has expired', {
						position: toast.POSITION.TOP_RIGHT,
						theme: 'colored',
					});
			}

			// Return a response
			return handlerResponse('Something went wrong!', errorObject);
		} else {
			if (
				errorObject.message.includes(
					'Wallet transaction failed, we could not credit the wallet'
				)
			) {
				console.log('Error handler hit');
				// Do nothing
			} else {
				SoundPlayer.playSound('error2');
				shouldDispatchAlert &&
					toast.error(errorObject.message, {
						position: toast.POSITION.TOP_RIGHT,
						theme: 'colored',
					});
			}

			// Return a response
			return handlerResponse('Something went wrong!', errorObject);
		}
	} catch (error) {
		console.log(
			'Error Handler System Failure - Error Experienced In Processing Error Object',
			error
		);
	}
};

export default handler;
