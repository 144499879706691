import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { BasicSubscription } from './BasicSubscription';
import { PremiumSubscription } from './PremiumSubscription';
import { getAllSubscriptionsApi } from 'store/slices/public/publicApi';
import { getUserFromSession } from 'utils/Auth';
import { useTranslation } from 'react-i18next';

const page_tabs = [
	// { title: 'Basic', href: 'basic', key: 'onboard.basic' },
	{ title: 'Premium', href: 'premium', key: 'onboard.premium' },
];

export function Subscribe() {
	const user = getUserFromSession();
	const [bundleData, setBundleData] = useState<any>([]);
	const [activeBundle, setActiveBundle] = useState<any>({});
	const [subscriptionTab, setSubscriptionTab] = useState('basic');
	const [selectedValidity, setSelectedValidity] = useState<any>({});
	// const [subscriptionValidity, setSubscriptionValidity] = useState<any>({});

	const { t } = useTranslation('common');

	const { data: subscriptionData } = useQuery(
		['get-all-subscription'],
		async () => await getAllSubscriptionsApi(),
		{
			onSuccess: (res: any) => {
				const mapData = res.data?.itemsList?.map((item: any) => ({
					_id: item?._id,
					duration: item?.subscriptionPeriod || item?.title,
					amount: item?.amount,
					freeDailyHunt: item?.dailyFreeHunterPlayCount
						? item?.dailyFreeHunterPlayCount
						: '',
					dailyFreeSpin: item?.dailyFreeHunterPlayCount
						? item?.dailyFreeHunterPlayCount
						: '',
					weeklyRaffle: item?.weeklyRaffleCount ? item?.weeklyRaffleCount : '',
					ice:
						item?.allocateGameAsset?.find(
							(ele: any) => ele.gameAssetKey?.toLowerCase() === 'ice'
						) || '',
					gameShield:
						item?.allocateGameAsset?.find(
							(ele: any) => ele.gameAssetKey?.toLowerCase() === 'gameshield'
						) || '',
					background:
						item?.allocateGameAsset?.find(
							(ele: any) => ele.gameAssetKey?.toLowerCase() === 'background'
						) || '',
					shaggy:
						item?.allocateGameCharacter?.find(
							(ele: any) => ele.gameCharacterKey?.toLowerCase() === 'shaggi'
						) || '',
					odumeje:
						item?.allocateGameCharacter?.find(
							(ele: any) => ele.gameCharacterKey?.toLowerCase() === 'odumeje'
						) || '',
				}));
				setBundleData(mapData);
			},
			retry: 0,
		}
	);

	useEffect(() => {
		if (bundleData?.length > 0) {
			const activeBundle = bundleData?.find(
				(bundle: any) => bundle.duration === 'basic plan'
			);
			setActiveBundle(activeBundle);
		}
	}, [bundleData]);

	const handleTab = (tab: any) => {
		setSubscriptionTab(tab?.href);

		if (bundleData?.length > 0) {
			const activeBundle = bundleData?.find((bundle: any) => {
				return bundle.duration.includes(tab.href);
			});
			if (activeBundle?._id === undefined) {
				setActiveBundle({});
			} else {
				setActiveBundle(activeBundle);
			}
		}
	};

	const handleComplete = () => {
		localStorage.setItem('isFirstTimeUser', 'false');
		window.location.href = '/';
	};

	return (
		<div
			className={`relative w-full h-screen px-4 overflow-hidden ${
				subscriptionTab === 'basic'
					? 'subscribe-background1'
					: 'subscribe-background2'
			}`}
		>
			<div className="relative py-12 h-full overflow-scroll">
				<div className="flex flex-col w-full max-w-[360px] mx-auto gap-4 justify-center items-center mb-8">
					<p className="text-primary text-[2.5rem] leading-tight font-medium text-center">
						{t('onboard.unlockPremium')}
					</p>
					<p className="text-[#FFFFFF80] text-[1rem] font-medium text-center">
						{t('onboard.subscribeForAccess')}
					</p>
				</div>

				<div className="flex flex-col w-full gap-3 pb-10">
					<nav className="bg-[#FFFFFF0A] border border-[#FFFFFF14] flex items-center justify-between rounded-[2rem] px-2 py-2 h-[60px] mb-4">
						{page_tabs?.length > 0 &&
							page_tabs?.map((tab, index) => (
								<div
									key={index}
									className={`${
										subscriptionTab === tab?.href
											? 'text-[#0F121D] bg-[#84C70D]'
											: 'text-grey'
									} rounded-[30px] px-4 h-[48px] text-[20px] flex items-center justify-center gap-1 font-semibold w-full`}
									onClick={() => handleTab(tab)}
								>
									<p>{t(tab?.key)}</p>
								</div>
							))}
					</nav>

					{subscriptionTab === 'basic' && (
						<BasicSubscription
							activeBundle={activeBundle}
							selectedValidity={selectedValidity}
							user={user}
							setSelectedValidity={setSelectedValidity}
							subscriptionData={subscriptionData}
						/>
					)}
					{subscriptionTab === 'premium' && (
						<PremiumSubscription
							activeBundle={activeBundle}
							selectedValidity={selectedValidity}
							user={user}
							setSelectedValidity={setSelectedValidity}
							subscriptionData={subscriptionData}
						/>
					)}

					<button
						className="text-primary text-[1rem]"
						onClick={() => handleComplete()}
					>
						{t('onboard.freeTrial')}
					</button>
				</div>
			</div>
		</div>
	);
}
