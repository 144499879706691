import arrowdraw from 'assets/audio/gamesounds/arrowdraw.mp3';
import arrowrelease from 'assets/audio/gamesounds/arrowrelease.mp3';
import birdflyaway from 'assets/audio/gamesounds/bidrflyaway.mp3';
import birdhit from 'assets/audio/gamesounds/birdhit.mp3';
import claim1 from 'assets/audio/gamesounds/claim1.mp3';
import claim2 from 'assets/audio/gamesounds/claim2.mp3';
import claim3 from 'assets/audio/gamesounds/claim3.mp3';
import error1 from 'assets/audio/gamesounds/error1.mp3';
import error2 from 'assets/audio/gamesounds/error2.mp3';
import error3 from 'assets/audio/gamesounds/error3.mp3';
import gameStart from 'assets/audio/gamesounds/game-start.mp3';
import hunterAmount from 'assets/audio/gamesounds/hunter-amount.mp3';
import hunterJoin from 'assets/audio/gamesounds/hunter-join.mp3';
import hunterCancel from 'assets/audio/gamesounds/hunter-cancel.mp3';
import menuSelect from 'assets/audio/gamesounds/menu-selector.mp3';
import arrowHit from 'assets/audio/gamesounds/arrowImpact.mp3';
import iceCrack from 'assets/audio/gamesounds/ice-water.mp3';
import wheelClick from 'assets/audio/gamesounds/wheelClick.mp3';

//Hunter Games Sounds
import indaboskiShoot from 'assets/audio/odogwuHunter/abitoshaker.mp3';
import indaboskiSelect from 'assets/audio/odogwuHunter/gatPowers.mp3';
import indaboskiBirdHit from 'assets/audio/odogwuHunter/indaboskiBahose.mp3';
import shaggiShoot from 'assets/audio/odogwuHunter/shaggii.mp3';
import shaggiSelect from 'assets/audio/odogwuHunter/thainkyou.mp3';
import shaggiBirdHit from 'assets/audio/odogwuHunter/eShockYou.mp3';

// Add the theme sound import
import themeSound from 'assets/audio/gamesounds/hammerthemesong.mp3';
import themeSound1 from 'assets/audio/gamesounds/hammerthemesound1.mp3';
import themeSound2 from 'assets/audio/gamesounds/hammerthemesound2.mp3';
import themeSound3 from 'assets/audio/gamesounds/hammerthemesound3.mp3';
import themeSound4 from 'assets/audio/gamesounds/hammerthemesound4.mp3';
import themeSound5 from 'assets/audio/gamesounds/hammerthemesound5.mp3';
interface Sounds {
	[key: string]: HTMLAudioElement;
}

class SoundPlayer {
	static sounds: Sounds = {
		arrowDraw: new Audio(arrowdraw),
		arrowRelease: new Audio(arrowrelease),
		birdFlyAway: new Audio(birdflyaway),
		birdHit: new Audio(birdhit),
		claim1: new Audio(claim1),
		claim2: new Audio(claim2),
		claim3: new Audio(claim3),
		error1: new Audio(error1),
		error2: new Audio(error2),
		error3: new Audio(error3),
		gameStart: new Audio(gameStart),
		hunterAmount: new Audio(hunterAmount),
		hunterJoin: new Audio(hunterJoin),
		hunterCancel: new Audio(hunterCancel),
		menuSelect: new Audio(menuSelect),
		arrowHit: new Audio(arrowHit),
		iceCrack: new Audio(iceCrack),
		wheelClick: new Audio(wheelClick),
		indaboskiShoot: new Audio(indaboskiShoot),
		indaboskiSelect: new Audio(indaboskiSelect),
		indaboskiBirdHit: new Audio(indaboskiBirdHit),
		shaggiShoot: new Audio(shaggiShoot),
		shaggiSelect: new Audio(shaggiSelect),
		shaggiBirdHit: new Audio(shaggiBirdHit),
		themeSound: new Audio(themeSound),
		themeSound1: new Audio(themeSound1),
		themeSound2: new Audio(themeSound2),
		themeSound3: new Audio(themeSound3),
		themeSound4: new Audio(themeSound4),
		themeSound5: new Audio(themeSound5),
	};

	// Preload all sounds
	static preloadSounds() {
		Object.values(this.sounds).forEach((sound) => {
			sound.load(); // Preload each sound
		});
	}

	static currentThemeIndex: number = 0;

	// Initialize local storage settings if they do not exist
	static initializeSettings() {
		if (localStorage.getItem('sound') === null) {
			localStorage.setItem('sound', 'true');
		}
		if (localStorage.getItem('backgroundsound') === null) {
			localStorage.setItem('backgroundsound', 'true');
		}
	}

	static setAudioAttributes = (audioElement: HTMLAudioElement) => {
		// Setting audio category to ambient, so it doesn't interrupt background music
		audioElement.setAttribute('playsinline', 'true');
		audioElement.setAttribute('preload', 'auto');
	};

	static playSound = (soundName: string) => {
		const sound = this.sounds[soundName];
		const settings = localStorage.getItem('sound');

		if (sound && settings === 'true') {
			this.setAudioAttributes(sound); // Apply attributes
			if (!sound.paused) {
				sound.currentTime = 0; // Restart sound if it is already playing
				sound.volume = 0.5;
				sound.play();
			}
			sound
				.play()
				.catch((error) => console.error('Error playing sound:', error));
		} else {
			if (settings !== 'true') return;
			console.error(`Sound '${soundName}' not found`);
		}
	};

	static playSoundAsBackground = (soundName: string) => {
		const sound = this.sounds[soundName];
		const settings = localStorage.getItem('sound');

		if (sound && settings === 'true') {
			this.setAudioAttributes(sound);
			if (!sound.paused) {
				sound.currentTime = 0; // Restart sound if it is already playing
				sound.volume = 0.35;
				sound.play();
			}
			sound
				.play()
				.catch((error) => console.error('Error playing sound:', error));
		} else {
			console.error(`Sound '${soundName}' not found`);
		}
	};

	static playThemeSound = () => {
		const themeSounds = [
			// this.sounds.themeSound,
			this.sounds.themeSound1,
			this.sounds.themeSound2,
			this.sounds.themeSound3,
			this.sounds.themeSound4,
			this.sounds.themeSound5,
		];
		const settings = localStorage.getItem('sound');
		const background = localStorage.getItem('backgroundsound');

		if (settings === 'true' && background === 'true') {
			const theme = themeSounds[this.currentThemeIndex]; // Get the current theme sound
			this.setAudioAttributes(theme);
			theme.volume = 0.1; // Set low background volume
			theme.loop = false; // Disable looping for the individual sound

			// Play the current theme sound
			theme
				.play()
				.catch((error) => console.error('Error playing theme sound:', error));

			// Set up an event listener to play a random sound when the current one ends
			theme.onended = () => {
				const randomIndex = Math.floor(Math.random() * themeSounds.length); // Randomly select an index
				this.currentThemeIndex = randomIndex; // Update the current index to the random one
				this.playThemeSound(); // Play the next random sound
			};
		}
	};

	static stopThemeSound = () => {
		const themeSounds = [
			// this.sounds.themeSound,
			this.sounds.themeSound1,
			this.sounds.themeSound2,
			this.sounds.themeSound3,
			this.sounds.themeSound4,
			this.sounds.themeSound5,
		];

		// Stop all theme sounds
		themeSounds.forEach((theme) => {
			theme.pause(); // Stop the theme sound
			theme.currentTime = 0; // Reset to the beginning
		});
	};

	// Call this method when the game starts
	static onGameStart = () => {
		this.stopThemeSound(); // Stop the theme sound when the game starts
	};

	// Call this method when the game stops
	static onGameStop = () => {
		this.playThemeSound(); // Resume the theme sound when the game stops
	};

	// Call this method to initialize settings
	static initialize() {
		this.initializeSettings(); // Ensure settings are initialized
	}
}

// Initialize settings when the class is loaded
SoundPlayer.initialize();

// Call preloadSounds when the class is loaded
SoundPlayer.preloadSounds();

export default SoundPlayer;
