import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInitialState {
	idFrontSrc: string;
	idBackSrc: string;
	documentIdentificationNumber: any;
	documentExpirationDate: string;
	documentType: string;
	tonAmount: string;
	tonAddress: string;
	tonTransactionId: string;
}

export const initialState: IInitialState = {
	idFrontSrc: '',
	idBackSrc: '',
	documentIdentificationNumber: '',
	documentExpirationDate: '',
	documentType: 'VOTERS_CARD',
	tonAmount: '',
	tonAddress: '',
	tonTransactionId: '',
};

export const accountSlice = createSlice({
	name: 'hammer-games',
	initialState,
	reducers: {
		getIdFront: (state, action: PayloadAction<string>) => {
			state.idFrontSrc = action.payload;
		},
		getIdBack: (state, action: PayloadAction<string>) => {
			state.idBackSrc = action.payload;
		},
		getIdNumber: (state, action: PayloadAction<any>) => {
			state.documentIdentificationNumber = action.payload;
		},
		getExpirationDate: (state, action: PayloadAction<string>) => {
			state.documentExpirationDate = action.payload;
		},
		getDocumentType: (state, action: PayloadAction<string>) => {
			state.documentType = action.payload;
		},
		getTonAmount: (state, action: PayloadAction<string>) => {
			state.tonAmount = action.payload;
		},
		getTonAddress: (state, action: PayloadAction<string>) => {
			state.tonAddress = action.payload;
		},
		getTonTransactionId: (state, action: PayloadAction<string>) => {
			state.tonTransactionId = action.payload;
		},
	},
});

export const accountActions = accountSlice.actions;
export const accountReducer = accountSlice.reducer;
