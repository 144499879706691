import { useLocation, useNavigate } from 'react-router-dom';
import hammer_logo_2 from 'assets/icons/Hammer-logo-2.png';
import banner from 'assets/images/banner/promo-2.png';

export const PromoPage2 = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const referral = queryParams.get('ref');

	return (
		<div className="text-white p-4 mx-auto max-w-7xl">
			<div className="flex gap-2 justify-center mb-16">
				<img
					src={hammer_logo_2}
					alt="Logo"
					className="max-w-[99px] lg:max-w-[158px]"
				/>
			</div>

			<div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
				<div>
					<div>
						<div className="text-[2.8rem] lg:text-[5.25rem] leading-[3rem] lg:leading-[5.5rem] font-medium luckiest-guy-regular gradient-text text-center lg:text-left transform rotate-[-3deg] mb-8">
							<h2>Play games</h2>
							<h2>Have fun</h2>
						</div>
						<p className="text-[#F4FFE1] text-lg font-medium max-w-[420px] text-center lg:text-left">
							Be amongst the first 5 lucky players to win 1 million cowries
							(500,000 naira cash) each.
						</p>
					</div>

					<div className="mt-12">
						<p className="text-[#A7B1C2] text-center lg:text-left mb-2">
							Use the referral code:
						</p>
						<div className="max-w-[550px] bg-[#1B2636] border-[1.5px] lg:border-[3px] border-[#314663] border-dotted h-[54px] lg:h-[86px] rounded-lg flex items-center justify-center">
							<span className="text-white text-[25px] lg:text-[45px] fnt-bold">
								{referral}
							</span>
						</div>
						<div className="flex items-center justify-center lg:justify-start">
							<button
								onClick={() => {
									navigate(`/auth/email-registration?ref=${referral}`);
								}}
								className="bg-[#84C70D] text-[#0F121D] border-[#F6FFE4AD] border-[6.4px] mt-8 rounded-[5rem] text-[20px] lg:text-[29px] font-medium px-8 py-2"
							>
								Start Playing For FREE
							</button>
						</div>
					</div>
				</div>
				<div className="max-w-[470px]">
					<img src={banner} alt="banner" className="w-full mb-4" />
				</div>
			</div>
		</div>
	);
};
