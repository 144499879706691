import { useState, useEffect } from 'react';

export const PasswordStrength = ({ password }: { password: string }) => {
	const [strength, setStrength] = useState(0);

	useEffect(() => {
		strengthChecker(password);
	}, [password]);

	function strengthChecker(password: string) {
		//Regular Expressions.
		const regex = [];
		regex.push('[A-Z]'); //Uppercase Alphabet.
		regex.push('[a-z]'); //Lowercase Alphabet.
		regex.push('[0-9]'); //Digit.
		regex.push('[$@$!%*#?&]'); //Special Character.

		let passed = 0;

		//Validate for each Regular Expression.
		for (let i = 0; i < regex.length; i++) {
			if (new RegExp(regex[i]).test(password)) {
				passed++;
			}
		}

		setStrength(passed);
		return passed;
	}

	return (
		<div className="flex items-center gap-1 mb-1">
			<span className="text-xs">
				{strength === 1 && <span className="text-[#FF4A4A]">Weak</span>}
				{strength === 2 && <span className="text-danger">Average</span>}
				{strength === 3 && <span className="text-[#4F6BFF]">Strong</span>}
				{strength === 4 && <span className="text-[#84C70D]">Excellent</span>}
			</span>
			<span className="flex items-center bg-[#31384F] rounded-full h-[5px]">
				<span
					className={`w-[18px] h-full border-l-2 border-[#232838] ${
						strength > 0 ? 'bg-[#FF4A4A]' : 'bg-transparent'
					}`}
				></span>
				<span
					className={`w-[18px] h-full border-l-2 border-[#232838] ${
						strength > 1 ? 'btn-secondary' : 'bg-transparent'
					}`}
				></span>
				<span
					className={`w-[18px] h-full border-l-2 border-[#232838] ${
						strength > 2 ? 'bg-[#4F6BFF]' : 'bg-transparent'
					}`}
				></span>
				<span
					className={`w-[18px] h-full border-l-2 border-[#232838] ${
						strength > 3 ? 'bg-[#84C70D]' : 'bg-transparent'
					}`}
				></span>
			</span>
		</div>
	);
};
