import FreeCrown from 'assets/crowns/hunter-crown.svg';
import BasicCrown from 'assets/crowns/chief-crown.svg';
import PremiumCrown from 'assets/crowns/odogwu-crown.svg';

export const getUserCrown = (subscription: any) => {
	if (
		subscription?.subscriptionPlanId?.title.toLowerCase().includes('premium')
	) {
		return (
			<div className="flex gap-1 items-center">
				<img src={PremiumCrown} alt="Crown" className="w-[20px] h-[20px]" />
				<p className="text-[#848AA0] text-[12px] font-medium">Odogwu</p>
			</div>
		);
	} else if (subscription?.subscriptionPlanId?.title.includes('basic')) {
		return (
			<div className="flex gap-1 items-center">
				<img src={BasicCrown} alt="Crown" className="w-[20px] h-[20px]" />
				<p className="text-[#848AA0] text-[12px] font-medium">Chief</p>
			</div>
		);
	} else {
		return (
			<div className="flex gap-1 items-center">
				<img src={FreeCrown} alt="Crown" className="w-[20px] h-[20px]" />
				<p className="text-[#848AA0] text-[12px] font-medium">Hunter</p>
			</div>
		);
	}
};
