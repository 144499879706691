import close_btn from 'assets/icons/bot-modal-cancel.svg';

export const TelegramBotModal = ({
	isOpen,
	modalClass,
	// modalOpen,
	modalClose,
	modalChild,
	closeIcon,
}: any) => {
	return (
		<div
			className={
				isOpen
					? `h-[100vh] w-[100vw] bg-[#0E111A] bg-opacity-[80%] flex flex-col items-center justify-center z-[500] fixed top-0 left-0 overflow-hidden `
					: 'hidden'
			}
		>
			{closeIcon && (
				<img
					src={close_btn}
					onClick={() => modalClose && modalClose()}
					alt=""
					className="h-[3rem] absolute top-[5%] right-2 ml-auto z-[300] "
				/>
			)}
			<div className={`h-full w-full ${modalClass}`}>
				{isOpen && modalChild}
			</div>
		</div>
	);
};
