import { useLocation, useNavigate } from 'react-router-dom';
import hammer_logo_2 from 'assets/icons/Hammer-logo-2.png';
import banner from 'assets/images/banner/promo-1.png';

export const PromoPage = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const ref = queryParams.get('ref');

	return (
		<div className="text-white p-4 mx-auto max-w-[525px]">
			<div className="flex gap-2 justify-center mb-5">
				<img
					src={hammer_logo_2}
					alt="Logo"
					className="max-w-[99px] lg:max-w-[158px]"
				/>
			</div>

			<div>
				<img src={banner} alt="banner" className="w-full mb-4" />
				<p className="text-[#F4FFE1] text-[17px] lg:text-lg font-medium text-center max-w-[420px] mx-auto">
					Be amongst the first 5 lucky players to win 1 million cowries (500,000
					Naira cash) each.
				</p>
			</div>

			<div className="mt-12 text-center">
				<p className="text-[#A7B1C2] mb-2">Your referral code:</p>
				<div className="bg-[#1B2636] border border-[3px] border-[#314663] border-dotted h-[54px] lg:h-[86px] rounded-lg flex items-center justify-center">
					<span className="text-white text-[25px] lg:text-[45px] font-medium">
						{ref}
					</span>
				</div>

				<button
					onClick={() => {
						navigate(`/auth/email-registration?ref=${ref}`);
					}}
					className="bg-[#84C70D] text-[#0F121D] border-[#F6FFE4AD] border-[6.4px] mt-8 rounded-[5rem] text-[19px] lg:text-[29px] font-medium px-8 py-2"
				>
					Start Playing For FREE
				</button>
			</div>
		</div>
	);
};
