export const initDataUnsafe = {
	// query_id: process.env.REACT_APP_TELEGRAM_QUERY_ID,
	user: {
		id: Number(process.env.REACT_APP_TELEGRAM_USER_ID),
		first_name: process.env.REACT_APP_TELEGRAM_FIRSTNAME,
		last_name: '',
		username: process.env.REACT_APP_TELEGRAM_USERNAME,
		language_code: 'en',
		allows_write_to_pm: true,
	},
	auth_date: process.env.REACT_APP_TELEGRAM_AUTH_DATE,
	hash: process.env.REACT_APP_TELEGRAM_HASH,
};

export const initData = process.env.REACT_APP_TELEGRAM_INITDATA;
