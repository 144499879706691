import { useState } from 'react';
import { _getUser } from 'utils';
import { useNavigate } from 'react-router-dom';
import TextInput from 'components/inputs/text';
import { Button3d } from 'components/button/Button';
import { useAddNotification } from 'components/notification';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateMemberProfile } from 'store/slices/profile/profileApi';

export interface Props {
	modalClose?: () => void;
	// setOnboardingView: (e: any) => void;
}

export const CreateUsernameModal = () => {
	const user = _getUser();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const showNotification = useAddNotification();

	const [username, setUsername] = useState('');

	const handleComplete = () => {
		localStorage.setItem('isFirstTimeUser', 'false');
		navigate('/play');
	};

	const { isLoading, mutate } = useMutation(updateMemberProfile, {
		onError: (error: Error) => {
			showNotification({
				level: 'warning',
				text: error?.message,
			});
		},
		onSuccess: (res: any) => {
			showNotification({
				level: 'success',
				text: `${res?.message}`,
			});

			const old_user = { ...user };
			old_user.userName = username;

			localStorage.setItem('game-user-obj', JSON.stringify(old_user));
			queryClient.invalidateQueries(['user-profile']);

			handleComplete();
		},
		retry: 0,
	});

	const onSubmit = () => {
		if (username !== '') {
			const formInput = {
				userName: username,
			};

			mutate(formInput);
		}
	};

	return (
		<div className="h-full w-full ">
			<main className="absolute bottom-0 pb-[3rem] w-full flex flex-col items-center bg-[#0F121D] rounded-tl-[22px] rounded-tr-[22px] border-2 border-[#ffffff] border-opacity-[8%] ">
				<div className="w-full px-4 md:px-6 mt-[2.5rem] h-full flex flex-col items-center">
					<div className="mb-12 text-primary w-full">
						<p className="text-[1.5rem] mb-1 font-medium">Create Username</p>
					</div>

					<div className="w-full mb-4">
						<TextInput
							type={'text'}
							name={'customerLastName'}
							label={'Enter Preferred Username'}
							placeholder={'Enter Preferred Username'}
							className={'mb-4'}
							onChange={(e) => setUsername(e.target.value)}
							inputInfo={
								<p className="text-sm text-grey">Minimum of 6 characters</p>
							}
						/>
					</div>

					<Button3d
						text={'Done! Lets play'}
						onClick={() => onSubmit()}
						disabled={isLoading || username?.length < 6}
						loading={isLoading}
						className={`font-semibold w-full flex items-center justify-center h-[56px] mb-5`}
					/>
				</div>
			</main>
		</div>
	);
};
