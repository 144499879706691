import { useNavigate } from 'react-router-dom';
import logo from 'assets/icons/gamic-hammer-logos.png';
import banner from 'assets/images/banner/gamic-promo.png';

export const GamicPromoPage = () => {
	const navigate = useNavigate();
	return (
		<div className="text-white p-4 mx-auto max-w-[720px]">
			<div className="flex gap-2 justify-center mb-6">
				<img src={logo} alt="Logo" className="max-w-[210px] lg:max-w-[420px]" />
			</div>

			<div className="flex justify-center">
				<img src={banner} alt="banner" className="w-auto max-h-[50vh]" />
			</div>

			<div className="mt-8 lg:mt-12 text-center">
				<button
					onClick={() => {
						navigate(`/auth/email-registration?ref=gamic`);
					}}
					className="bg-[#84C70D] text-[#0F121D] border-[#F6FFE4AD] border-[6.4px] rounded-[5rem] text-[19px] lg:text-[29px] font-medium px-8 py-2"
				>
					Start playing now!
				</button>
			</div>
			<div className="mt-8">
				<p className="text-[#575C6B] text-[12px] md:text-[17px] lg:text-lg font-medium text-center mb-3">
					Hammer Games is an interactive gaming platform that is made up of
					different games with African cultures.
				</p>
				<p className="text-[#575C6B] text-[12px] md:text-[17px] lg:text-lg font-medium text-center mb-3">
					© 2024 Hammer Games
				</p>
			</div>
		</div>
	);
};
