import { Button3d } from 'components/button/Button';
import { ModalLayout } from '../modalLayout';
import newest from 'assets/images/newest.png';
import green_circle from 'assets/images/green-circle.png';
import checked from 'assets/images/checked.png';
import { useState } from 'react';
import exclusive_img from 'assets/images/exclusive-img.png';
import exclusive_title from 'assets/images/exclusive-title.png';
import discount_img from 'assets/images/discount-img.png';
import discount_title from 'assets/images/discount-text.png';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {
	closeModal: () => void;
	setNewPlayer?: (e: boolean) => void;
};

export const NewestHunterModal = ({ closeModal, setNewPlayer }: Props) => {
	return (
		<ModalLayout showCloseIcon={false} showBgColor={false}>
			<div
				style={{
					backgroundImage: `url(${newest})`,
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: '100% 100%',
				}}
				className="h-[50vh] flex flex-col items-end"
			>
				<Button3d
					text="Let me in"
					onClick={() => {
						localStorage.setItem('new-player', 'false');
						setNewPlayer && setNewPlayer(false);
						closeModal();
					}}
					className={`w-[80%] text-[0.875rem] h-[3rem] mb-5 mt-auto mx-auto`}
				/>
			</div>
		</ModalLayout>
	);
};

export const DiscountModal = ({ closeModal }: Props) => {
	const [check, setCheck] = useState(false);
	const { t } = useTranslation('common');

	return (
		<ModalLayout
			onClose={() => closeModal()}
			showCloseIcon={false}
			showBgColor={false}
		>
			<div className="h-[55vh] px-3 py-2 bg-[#0F121D] rounded-[16px] flex flex-col items-end">
				<img src={discount_img} alt="" className="mb-4" />
				<img src={discount_title} alt="" />
				<div className="w-[80%] flex flex-col items-center gap-5 mb-5 mt-auto mx-auto">
					<div className="flex gap-2 items-center">
						{check ? (
							<img
								src={checked}
								alt=""
								onClick={() => {
									setCheck(!check);
								}}
								className="w-[1.2rem]"
							/>
						) : (
							<img
								src={green_circle}
								alt=""
								onClick={() => {
									setCheck(!check);
								}}
								className="w-[1.2rem]"
							/>
						)}
						<span className="text-[0.875rem] text-[#ffffff]">
							No more reminders for today
						</span>
					</div>
					<Button3d
						text={t('onboard.subscribeNow')}
						onClick={() => {
							closeModal();
						}}
						className={`w-full text-[0.875rem] h-[3rem] `}
					/>
				</div>
			</div>
		</ModalLayout>
	);
};

export const ExclusiveModal = ({ closeModal }: Props) => {
	const { t } = useTranslation('common');
	const navigate = useNavigate();

	return (
		<ModalLayout
			onClose={() => closeModal()}
			showCloseIcon={false}
			showBgColor={false}
		>
			<div className="h-[60vh] px-3 py-2 bg-[#0F121D] rounded-[16px] flex flex-col items-end">
				<img src={exclusive_img} alt="" className="mb-4 h-[8rem] mx-auto" />
				<img src={exclusive_title} alt="" className="" />
				<p className="text-[0.875rem] text-[#FFFFFF99] text-opacity-[60%] text-center px-4">
					{t('dontMissOut')}
				</p>

				<div className="w-[80%] flex flex-col items-center gap-5 mb-5 mt-auto mx-auto">
					<Button3d
						text={t('onboard.subscribeNow')}
						onClick={() => {
							navigate('/subscriptions');
						}}
						className={`w-full text-[0.875rem] h-[3rem] `}
					/>
				</div>
			</div>
		</ModalLayout>
	);
};
