import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import TextInput from 'components/inputs/text';
import { AuthFormAction } from 'components/forms/auth/layout';
import { Button } from 'components/button/Button';
import { ReactComponent as Back } from 'assets/icons/arrow-left-circle.svg';
import { authActions } from 'store/slices/authSlice';
import { cleanInput } from 'utils/cleanInput';
import { loginUserApi } from 'api/apiRequest';
import { toast } from 'react-toastify';
import successHandler from 'handlers/successHandler';
import errorHandler from 'handlers/errorHandler';

// This component renders the password creation page for user registration
export const VasEnterPasswordPage: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [password, setPassword] = useState('');
	const [data, setData] = useState({
		username: '',
		password: '',
		userPhoneCountryCode: 'NG',
	});

	const queryParams = new URLSearchParams(location.search);
	const phone = queryParams.get('ref') || '';
	const phone_pattern = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;

	// Handles changes to the confirm password input field
	// const handleChange = (e: any) => {
	// 	const { value } = e.target;
	// 	setConfirmPassword(value);
	// 	// Enable the continue button only if passwords match and are not empty
	// 	setDisabled(!(value === password && value !== ''));
	// };

	// Setup mutation for registering a user with error handling and redirection on success

	// Handles form submission for registration
	const onSubmit = () => {
		const formData = {
			username: phone,
			password: password,
			userPhoneCountryCode: 'NG',
		};
		cleanInput(formData);

		setData({
			...data,
			username: phone,
			password: password,
			userPhoneCountryCode: 'NG',
		});
		console.log(formData);
		mutate(formData);
	};

	const { isLoading, mutate } = useMutation(loginUserApi, {
		onError: (error: any) => {
			if (error && error?.action === 'not_verified_email') {
				if (phone_pattern.test(data?.username)) {
					// setFormView('resend-phone-otp');
					dispatch(authActions.regPhone(data?.username));
					dispatch(authActions.regCountry(data?.userPhoneCountryCode));
				} else {
					navigate('/auth/login/resend-otp');
					dispatch(authActions.regEmail(data?.username));
				}
			} else if (error && error?.message_language_code === 'auth_0153') {
				navigate('/auth/login/authenticator');
				dispatch(authActions.regEmail(data?.username));
				dispatch(authActions.regPassword(data?.password));
			} else if (error && error?.action === 'not_verified_phone') {
				toast.error(error?.message, {
					position: toast.POSITION.TOP_RIGHT,
					theme: 'colored',
					autoClose: 6000,
				});
				// setFormView('resend-phone-otp');
				dispatch(authActions.regPhone(data?.username));
				dispatch(authActions.regPassword(data?.password));
				dispatch(authActions.regCountry(data?.userPhoneCountryCode));
			} else {
				errorHandler(error, true);
			}
		},
		onSuccess: (res: any) => {
			if (
				res?.data?.accessSecret === null ||
				res?.action === 'country_not_in_profile'
			) {
				dispatch(authActions.regAccessToken(res?.data?.accessToken));
				navigate('/auth/complete-registration');
			} else {
				localStorage.setItem('game-user-token', res?.data?.accessToken);
				localStorage.setItem('game-user-secret', res?.data?.accessSecret);
				if (res?.data?.user) {
					localStorage.setItem(
						'game-user-obj',
						JSON.stringify(res?.data?.user)
					);
				}
				successHandler(res, true);
				navigate('/play');
			}
		},
		retry: 0,
	});

	return (
		<div className="lg:grid grid-cols-1 items-center gap-5 text-white px-0 sm:px-4">
			<div className="flex gap-2 items-center mb-8">
				<Back
					className="w-[34px] h-[34px] cursor-pointer"
					onClick={() => {
						navigate(-1);
						dispatch(authActions.regAdvocateUsername(''));
					}}
					data-testid="back-to-homepage"
				/>
				<h3 className="text-2xl font-medium">Enter Password</h3>
			</div>
			<AuthFormAction>
				<div className="mb-10 flex items-start justify-between gap-4">
					<div>
						<p className="font-medium text-grey">Phone Number:</p>
						<p className="font-medium text-white">0{phone}</p>
					</div>
					<button
						type="button"
						onClick={() => {
							navigate(-1);
						}}
						className="font-medium text-green"
					>
						Edit
					</button>
				</div>

				<h3 className="font-medium text-grey text-[15px] mb-6">
					Please enter your password below to login.
				</h3>

				<form>
					<TextInput
						label={'Enter password'}
						passwordInput={true}
						data-testid="password"
						onChange={(e) => {
							setPassword(e.target.value);
							dispatch(authActions.regPassword(e?.target?.value));
						}}
					/>
					<div className="flex justify-end mb-8 mt-6 sm:mb-4">
						<span
							data-testid="login-forgot-password"
							className="text-[14px] text-green font-medium cursor-pointer"
							onClick={() => {
								navigate('/auth/forgot-password');
							}}
						>
							Forgot password?
						</span>
					</div>

					<Button
						text={'Continue'}
						type="button"
						disabled={password.length < 5}
						loading={isLoading}
						data-testid="registration-button"
						className={
							'text-[15px] text-black font-medium btn-primary h-[56px] rounded-full mb-3 mt-8 justify-center block w-full px-8'
						}
						onClick={() => onSubmit()}
					/>
				</form>
			</AuthFormAction>
		</div>
	);
};
