import { feature_mark } from 'assets';

type Props = {
	feature: string;
	className?: string;
};

export const BundleFeature = ({ feature, className }: Props) => {
	return (
		<div className={`flex items-center gap-2 mb-2 ${className}`}>
			<img src={feature_mark} alt="" />
			<span className="text-[#FFFFFF] text-[0.875rem] font-semibold capitalize">
				{feature}
			</span>
		</div>
	);
};
