import { useNavigate } from 'react-router-dom';
import logo from 'assets/icons/Hammer-logo-2.png';
import banner from 'assets/images/banner/hammertribe.png';

export const JayFundPromoPage = () => {
	const navigate = useNavigate();
	return (
		<div className="text-white p-4 mx-auto max-w-[720px]">
			<div className="flex gap-2 justify-center mb-6">
				<img src={logo} alt="Logo" className="max-w-[150px] lg:max-w-[300px]" />
			</div>
			<div className="mt-3 mb-3">
				<p className="text-white text-[18px] md:text-[28px] font-medium text-center">
					JayFund has invited you to play <br /> Hammer Games.
				</p>
			</div>

			<div className="flex justify-center">
				<img src={banner} alt="banner" className="w-auto max-h-[38vh]" />
			</div>

			<div className="mt-8 lg:mt-12 text-center">
				<button
					onClick={() => {
						navigate(`/auth/email-registration?ref=jayfunds11`);
					}}
					className="bg-[#84C70D] text-[#0F121D] border-[#F6FFE4AD] border-[6.4px] rounded-[5rem] text-[19px] lg:text-[29px] font-medium px-8 py-2"
				>
					Start playing now!
				</button>
			</div>
			<div className="mt-8">
				<p className="text-[#575C6B] text-[12px] md:text-[17px] lg:text-lg font-medium text-center mb-3">
					Be amongst the first 5 lucky players to win 1 million cowries (500,000
					Naira cash) each.
				</p>
				<p className="text-[#575C6B] text-[12px] md:text-[17px] lg:text-lg font-medium text-center mb-3">
					© 2024 Hammer Games
				</p>
			</div>
		</div>
	);
};
