import { apiService } from './axios';

export const getGlobalConfig = async () => {
	const response = await apiService().get('/data/global-configurations');
	return response;
};

export const getUserProfile = async () => {
	const response = await apiService().get('/member/profile');
	return response;
};

export const getSpinUserProfile = async () => {
	const response = await apiService({ game: 'spin' }).get(
		'/member/profile?game=spin'
	);
	return response;
};

export const getHunterUserProfile = async () => {
	const response = await apiService({ game: 'hunter' }).get(
		'/member/profile?game=hunter'
	);
	return response;
};

export const start2FA = async (data: object) => {
	const payload = { ...data };
	const response = await apiService(data).post({
		url: '/auth/2fa/start/enable',
		payload,
	});
	return response;
};

export const enable2FA = async (data: object) => {
	const payload = { ...data };
	const response = await apiService(data).post({
		url: '/auth/2fa/create/enable',
		payload,
	});
	return response;
};

export const disable2FA = async (data: object) => {
	const payload = { ...data };
	const response = await apiService(data).post({
		url: '/auth/2fa/sec/disable',
		payload,
	});
	return response;
};

export const getKycStatus = async () => {
	const response = await apiService().get('/member/kyc');
	return response;
};

export const submitLevel2Kyc = async (data: object) => {
	const payload = { ...data };
	const response = await apiService(data).post({
		url: '/member/kyc',
		payload,
	});
	return response;
};

export const getDailyBonusApi = async () => {
	const response = await apiService({ numberOfDays: '7' }).get(
		`/member/gems-economy/gems/consecutive-days?numberOfDays=7`
	);
	return response;
};

export const getLoginDailyBonusApi = async () => {
	const response = await apiService().get(
		`/member/gems-economy/login/consecutive-login-day`
	);
	return response;
};

export const claimLoginDailyBonusApi = async () => {
	const response = await apiService().post({
		url: `/member/gems-economy/login/consecutive-login-day/claim`,
	});
	return response;
};

export const getBidsHistory = async () => {
	const response = await apiService().get(`/member/game-center/hunter/bids`);
	return response;
};

export const getBadges = async () => {
	const response = await apiService().get(
		`/member/gems-economy/achievements/badges`
	);
	return response;
};

export const getDailyLeaderboardGemsReward = async () => {
	const response = await apiService().get(`/member/gems-reward`);
	return response;
};

export const claimDailyLeaderboardRewardApi = async () => {
	const data = {
		period: 'yesterday',
		form: 'leaderboard',
	};

	const response = await apiService(data).post({
		url: `/member/gems-economy/reward/claim`,
		payload: data,
	});
	return response;
};

export const claimMissionReward = async (data: object) => {
	const payload = { ...data };

	const response = await apiService(data).post({
		url: `/member/gems-economy/mission/reward/claim`,
		payload,
	});
	return response;
};

export const verifyMobileNumberKyc = async (data?: any) => {
	const payload = { ...data };
	const response = await apiService(payload).post({
		url: '/member/phone-number/otp',
		payload,
	});

	return response;
};

export const verifyPhoneOtpApi = async (data?: any) => {
	const payload = { ...data };
	const response = await apiService(payload).post({
		url: '/member/phone-number/verify-otp',
		payload,
	});

	return response;
};

export const getAllSubscriptions = async () => {
	const response = await apiService().get(`/public/subscription/plan`);
	return response;
};

export const getMemberSubscriptions = async () => {
	const response = await apiService().get(`/member/subscription`);
	return response;
};

export const subscribeMember = async (data?: any) => {
	const payload = { ...data };
	const response = await apiService(data).post({
		url: '/member/subscription',
		payload,
	});

	return response;
};
