import { useState } from 'react';
import { Button3dMini } from 'components/button/Button';
// import useCurrencyConverter from 'hooks/useCurrencyConverter';
// import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { clipText, formatMoney } from 'utils';
import { cowryIcon } from 'assets';
import { ConfirmStorePurchase } from 'components/modals/marketplace/ConfirmStorePurchase';
import { TelegramBotModal } from 'components/modals/TelegramBotModal';

const MarketItem = ({
	// isTrending,
	// productCurrencyUsdValue,
	// fiatCurrencyName,
	// id,
	titleLength = 18,
	isFeatured,
	title,
	priceInGameCurrency,
	gameCurrencyName,
	image,
	product,
	listType,
}: {
	isTrending?: boolean;
	isFeatured?: boolean;
	id?: number;
	titleLength?: number;
	title?: string;
	priceInGameCurrency: number;
	gameCurrencyName?: string | number;
	image?: string;
	product?: object;
	listType?: string;
	productCurrencyUsdValue?: number;
	fiatCurrencyName?: string;
}) => {
	const [modal, setModal] = useState({ isOpen: false, type: '', modalObj: {} });
	const modalOpen = (type: string, modalObj?: any) =>
		setModal({ isOpen: true, type: type, modalObj });
	const modalClose = (e?: boolean) =>
		setModal({ isOpen: e || false, type: '', modalObj: {} });
	return (
		<div>
			{listType === 'trending' && (
				<div className="flex gap-6">
					<div className="bg-[#FFFFFF0A] border border-[#FFFFFF14] rounded-[12px]">
						<div className="retative">
							<img
								src={image}
								alt="item"
								className="w-[7rem] h-[6rem] object-cover rounded-[6px] mb-2"
							/>
							{isFeatured && (
								<div className="text-text-dark absolute top-4 left-4 bg-white py-1 px-[14px] rounded-3xl font-medium text-xs">
									Featured
								</div>
							)}
						</div>
					</div>

					<div>
						<p className="text-[16px] text-white font-bold capitalize">
							{clipText(`${title}`, titleLength)}
						</p>
						<div className="flex items-center gap-1">
							<img
								src={cowryIcon}
								alt="cowryIcon"
								className="h-[13px] w-auto"
							/>
							<p className="text-[13px] font-medium text-text-white">
								{formatMoney(priceInGameCurrency, false)} {gameCurrencyName}
							</p>
						</div>

						<Button3dMini
							text={'Purchase'}
							data-testid="hammer-purchase-btn"
							onClick={() => modalOpen && modalOpen('item-detail', product)}
							className={`flex items-center justify-center h-[30px] min-w-[112px] mt-3`}
						/>
					</div>
				</div>
			)}

			{modal.isOpen && modal.type === 'item-detail' && (
				<TelegramBotModal
					isOpen={modal.isOpen}
					modalClose={modalClose}
					closeIcon={true}
					modalChild={
						<ConfirmStorePurchase
							openModal={modalOpen}
							modalProp={modal.modalObj}
						/>
					}
				/>
			)}
		</div>
	);
};

// const FiatConversion = ({ amount }: any) => {
// 	const { fiatValue } = useCurrencyConverter({ amount });

// 	const { localCurrency } = useSelector(
// 		(state: any) => state.userReducer.userProfile
// 	);
// 	const fiatCurrencyName = localCurrency?.local?.symbol;

// 	return (
// 		<span className="text-secondary text-xs ml-1">
// 			~{formatMoney(fiatValue, false)} {fiatCurrencyName}
// 		</span>
// 	);
// };

export default MarketItem;
