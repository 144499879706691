import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// interface Transactions {
// 	itemsList: object[];
// 	paginator: object;
// }
interface IInitialState {
	fiatInvoices: object;
	cryptoInvoices: object;
	voucherInvoices: object;
	rewardInvoices: object;
}

export const initialState: IInitialState = {
	fiatInvoices: {},
	cryptoInvoices: {},
	voucherInvoices: {},
	rewardInvoices: {},
};

export const paymentSlice = createSlice({
	name: 'hammer-games',
	initialState,
	reducers: {
		getFiatInvoices: (state, action: PayloadAction<object>) => {
			state.fiatInvoices = action.payload;
		},
		getCryptoInvoices: (state, action: PayloadAction<object>) => {
			state.cryptoInvoices = action.payload;
		},
		getVoucherInvoices: (state, action: PayloadAction<object>) => {
			state.voucherInvoices = action.payload;
		},
		getRewardInvoices: (state, action: PayloadAction<object>) => {
			state.rewardInvoices = action.payload;
		},
	},
});

export const paymentActions = paymentSlice.actions;
export const paymentReducer = paymentSlice.reducer;
