import {
	bonusDay1,
	bonusDay2,
	bonusDay3,
	bonusDay4,
	bonusDay5,
	bonusDay6,
	bonusDay7,
} from 'assets';
import { Button3d } from 'components/button/Button';
import { ModalLayout } from 'components/modals';
import Lottie from 'lottie-react';
import loading from '../../assets/Sucess_popup.json';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
	claimLoginDailyBonusApi,
	getLoginDailyBonusApi,
} from 'api/profileApiService';
import { getTodayDate } from 'utils';
import errorHandler from 'handlers/errorHandler';
import claimedBg from 'assets/images/claimed.svg';
import lockedIcon from 'assets/images/lock.svg';
import SoundPlayer from 'components/sounds/GameSounds';
import { useTranslation } from 'react-i18next';

// type Props = {}

const imageFilenames = [
	bonusDay1,
	bonusDay2,
	bonusDay3,
	bonusDay4,
	bonusDay5,
	bonusDay6,
	bonusDay7,
];

const DayBonusCard = ({ day, todayGems, todayDate, isClaimed }: any) => {
	const isToday = todayDate === getTodayDate();

	// Get the image filename based on the day
	const imageSrc = imageFilenames[day - 1];

	return (
		<div className="relative">
			<div
				className={`one md:w-[9.299rem] rounded-[0.87rem] px-2 py-1 md:px-2 flex flex-col items-center gap-1  ${
					isToday && !isClaimed ? 'bg-[#E6F4CF]' : 'bg-[#13151D] opacity-50'
				}`}
			>
				<p
					className={`${
						isToday && !isClaimed ? 'text-text-dark' : 'text-primary'
					}  text-[0.8rem] md:text-[1.25rem] font-medium`}
				>
					Day {day}
				</p>
				<img
					src={imageSrc}
					alt=""
					className="h-[3rem] md:h-[4rem] sm:h-[2rem]"
				/>
				<p
					className={`${
						isToday && !isClaimed ? 'text-text-dark' : 'text-tertiary'
					} text-[0.6rem] md:text-[0.94rem] font-medium  text-center`}
				>
					+{todayGems} Gems
				</p>
			</div>
			{isClaimed && (
				<img src={claimedBg} alt="claimedBg" className="absolute top-[40%]" />
			)}
			{!isToday && !isClaimed && (
				<img
					src={lockedIcon}
					alt="lockedIcon"
					className="absolute top-[30%] right-[30%] w-[2rem] lg:w-auto"
				/>
			)}
		</div>
	);
};

export const DailyBonus = ({
	open,
	setOpen,
	isAPage,
}: {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isAPage?: true;
}) => {
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const [isSuccess, setIsSuccess] = useState(false);
	const [isFirstTime, setIsFirstTime] = useState(false);

	const { t } = useTranslation('common');

	const { data: dailyBonusData } = useQuery(
		['daily-bonus'],
		async () => await getLoginDailyBonusApi(),
		{
			retry: 0,
		}
	);

	const sortedData = dailyBonusData?.data?.sort((a: any, b: any) => {
		const loginDayA =
			a.day && a.day.loginDay !== undefined
				? a.day.loginDay
				: Number.MAX_SAFE_INTEGER;
		const loginDayB =
			b.day && b.day.loginDay !== undefined
				? b.day.loginDay
				: Number.MAX_SAFE_INTEGER;
		return loginDayA - loginDayB;
	});

	const firstLineDays = sortedData?.slice(0, 3) || [];
	const secondLineDays = sortedData?.slice(3, 6) || [];

	useEffect(() => {
		if (
			firstLineDays[0]?.day === null &&
			firstLineDays[1]?.day === null &&
			firstLineDays[2]?.day === null
		) {
			setIsFirstTime(true);
		}
	}, [firstLineDays]);

	const { isLoading, mutate } = useMutation(claimLoginDailyBonusApi, {
		onError: (error: any) => {
			errorHandler(error, true);
		},
		onSuccess: () => {
			setIsSuccess(true);
			playClaimGemsSound();
			queryClient.invalidateQueries(['user-profile']);
		},
		retry: 0,
	});
	const playClaimGemsSound = () => {
		SoundPlayer.playSound('claim1');
	};

	const handleClaimGem = () => {
		mutate();
	};

	const isTodayInDailyBonusData = dailyBonusData?.data?.find(
		(item: any) => item.day?.todayDate === getTodayDate()
	);

	// console.log(dailyBonusData, 'dailyBonusDatadailyBonusData');
	const renderContent = () => (
		<div className="relative flex items-center justify-center bg-  z-10 ">
			<div
				className={`bg-secondary border-[5px] border-[#232838] relative rounded-[1.66rem] min-w-auto md:min-w-[35rem] w-[95%] md:w-auto ${
					!isAPage ? 'flex flex-col' : 'pt-3'
				} items-center pb-8 box-border px-5`}
			>
				{!isSuccess ? (
					<>
						{' '}
						<div className="bonus-head md:w-[20rem] flex flex-col ">
							{!isAPage && (
								<h4 className="btn-primary p-2  bonus-title flex items-center justify-center text-[1.5rem] md:text-[2.33rem] font-medium capitalize">
									{t('util.dailyBonus')}
								</h4>
							)}
							<p
								className={`text-[1rem] md:text-[1.25rem] text-primary font-[700] ${
									!isAPage && 'text-center'
								} mt-1`}
							>
								{t('util.collectGemsdaily')}
							</p>
							<p
								className={`text-secondary text-[0.75rem] md:text-[0.9rem] font-medium ${
									!isAPage && 'text-center'
								}`}
							>
								{t('util.log7Days')}
							</p>
						</div>
						<div className="bonus-body mt-6 mb-8 flex gap-4 ">
							<div>
								<div className="grid grid-cols-3 gap-3 mb-3">
									{firstLineDays?.map((data: any, index: number) => {
										return (
											<div className="col-one flex flex-col gap-3" key={index}>
												<>
													<DayBonusCard
														day={index + 1}
														todayGems={data?.day?.gemsAmount || 0}
														todayDate={data?.day?.todayDate || ''}
														isClaimed={data?.day?.isClaimed}
													/>
												</>
											</div>
										);
									})}
								</div>
								<div className="grid grid-cols-3  gap-3">
									{secondLineDays?.map((data: any, index: number) => {
										return (
											<div className="col-one flex flex-col gap-3" key={index}>
												<>
													<DayBonusCard
														day={index + 4}
														todayGems={data?.day?.gemsAmount || 0}
														todayDate={data?.day?.todayDate || ''}
														isClaimed={data?.day?.isClaimed}
													/>
												</>
											</div>
										);
									})}
								</div>
							</div>
							<div className="relative h-full">
								<div
									className={`seven md:w-[9.299rem] rounded-[0.87rem] p-2 flex flex-col items-center justify-center gap-3  ${
										sortedData?.[6]?.day?.todayDate || '' === getTodayDate()
											? 'bg-[#E6F4CF]'
											: 'bg-[#13151D] opacity-50'
									} `}
								>
									<p
										className={`${
											sortedData?.[6]?.day?.todayDate || '' === getTodayDate()
												? 'text-text-dark'
												: 'text-primary'
										}   text-[0.8rem] md:text-[1.25rem] font-medium`}
									>
										Day 7
									</p>
									<img
										src={bonusDay7}
										alt=""
										className="w-[4rem] md:w-[7rem] my-10"
									/>
									<p
										className={`${
											sortedData?.[6]?.day?.todayDate || '' === getTodayDate()
												? 'text-text-dark'
												: 'text-tertiary'
										}  text-[0.6rem] md:text-[0.94rem] font-medium`}
									>
										+{sortedData?.[6]?.day?.gemsAmount || 0} Gems
									</p>
								</div>
								{sortedData?.[6]?.day?.isClaimed && (
									<img
										src={claimedBg}
										alt="claimedBg"
										className="absolute top-[40%]"
									/>
								)}
								{!sortedData?.[6]?.day?.isClaimed &&
									(sortedData?.[6]?.day?.todayDate ||
										'' !== getTodayDate()) && (
										<img
											src={lockedIcon}
											alt="lockedIcon"
											className="absolute top-[30%] right-[30%] w-[2rem] lg:w-auto"
										/>
									)}
							</div>
						</div>
						{isFirstTime === true ? (
							<p className="text-stone-300 text-[16px] md:text-[20px] lg:mb-10 mb-6 font-medium text-center">
								{t('util.collectRegistrationBonus')}
							</p>
						) : (
							<Button3d
								onClick={() => {
									handleClaimGem();
								}}
								disabled={
									isTodayInDailyBonusData?.day?.isClaimed === true && true
								}
								loading={isLoading}
								text={t('util.collectGems')}
								className={`${
									isAPage && 'flex mx-auto'
								} font-semibold w-full flex items-center justify-center h-[56px] mb-5 capitalize`}
							/>
						)}
					</>
				) : (
					<div className="">
						<div className="relative w-full">
							<div className="flex absolute mx-auto ">
								<Lottie
									loop={true}
									autoplay={true}
									animationData={loading}
									rendererSettings={{
										preserveAspectRatio: 'xMidYMid slice',
									}}
								/>
							</div>
						</div>
						<p className="text-[20px] md:text-[32px] text-primary font-[700] text-center lg:mt-[15rem] mt-[11rem] mb-3">
							{t('util.collectedTodayGems')} <br />{' '}
							{isTodayInDailyBonusData?.day?.gemsAmount} Gems!{' '}
						</p>
						<p className="text-secondary text-[20px] md:text-[25px] lg:mb-10 mb-6 font-medium text-center">
							{t('util.loginTomorrow')}
						</p>

						<div className=" relative w-full">
							<Button3d
								onClick={() => {
									setOpen(false);
									navigate('/play');
								}}
								text={t('util.playGame')}
								className="font-semibold w-full flex items-center justify-center h-[56px] mb-5 capitalize"
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
	return (
		<>
			{isAPage ? (
				renderContent()
			) : (
				<ModalLayout
					open={open}
					setOpen={setOpen}
					width={'full'}
					widthFit={true}
					bgColor={''}
					noSpacing={true}
					showCloseBtn={false}
				>
					{renderContent()}
				</ModalLayout>
			)}
		</>
	);
};
