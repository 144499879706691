import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInitialState {
	items: string[];
}

export const initialState: IInitialState = {
	items: [],
};

export const gamesSlice = createSlice({
	name: 'hammer-games',
	initialState,
	reducers: {
		addItem: (state, action: PayloadAction<string>) => {
			state.items = [...state.items, action.payload];
		},
	},
});

export const gamesActions = gamesSlice.actions;
export const gamesReducer = gamesSlice.reducer;
