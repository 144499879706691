import { apiService } from './axios';
import { publicApiService } from './publicApiService';

export const registerUserApi = async (data: object) => {
	const payload = { ...data };
	const response = await publicApiService.post({
		url: '/auth/registration/start',
		payload,
	});
	return response;
};

export const checkVasUserApi = async (data: object) => {
	const payload = { ...data };
	const response = await publicApiService.post({
		url: '/auth/registration/start/vas',
		payload,
	});
	return response;
};

export const verifyUserApi = async (data: object) => {
	const payload = { ...data };
	const response = await publicApiService.post({
		url: '/auth/registration/email-verification',
		payload,
	});
	return response;
};

export const verifyPhoneApi = async (data: object) => {
	const payload = { ...data };
	const response = await publicApiService.post({
		url: '/auth/registration/phone-verification',
		payload,
	});
	return response;
};

export const resendVerificationApi = async (data: object) => {
	const payload = { ...data };
	const response = await publicApiService.post({
		url: '/auth/registration/resend-code',
		payload,
	});
	return response;
};

export const completeRegistrationApi = async (data: object) => {
	const payload = { ...data };
	const response = await apiService(data).post({
		url: '/auth/registration/complete-register',
		payload,
	});
	return response;
};

export const loginUserApi = async (data: object) => {
	const payload = { ...data };
	const response = await publicApiService.post({
		url: '/auth/login',
		payload,
	});
	return response;
};

export const externalCommunityLogin = async (data: object) => {
	const payload = { ...data };
	const response = await publicApiService.post({
		url: '/auth/social/community',
		payload,
	});
	return response;
};

export const mobileLoginViaAutoLogin = async (data: object) => {
	const payload = { ...data };
	const response = await publicApiService.post({
		url: '/auth/login/auto',
		payload,
	});
	return response;
};

export const forgotPasswordApi = async (data: object) => {
	const payload = { ...data };
	const response = await publicApiService.post({
		url: '/auth/password/forgot',
		payload,
	});
	return response;
};

export const resetPasswordApi = async (data: object) => {
	const payload = { ...data };
	const response = await publicApiService.post({
		url: '/auth/password/reset',
		payload,
	});
	return response;
};

export const forgotPasswordUserApi = async (data: object) => {
	const payload = { ...data };
	const response = await publicApiService.post({
		url: '/auth/password/forgot',
		payload,
	});
	return response;
};

export const resetPasswordUserApi = async (data: object) => {
	const payload = { ...data };
	const response = await publicApiService.post({
		url: '/auth/password/reset',
		payload,
	});
	return response;
};

export const autoLoginApi = async (data: object) => {
	const payload = { ...data };
	const response = await publicApiService.post({
		url: '/auth/login/auto',
		payload,
	});
	return response;
};

export const socialLoginGoogle = async (data: object) => {
	const payload = { ...data };
	const response = await publicApiService.post({
		url: '/auth/social/google',
		payload,
	});
	return response;
};

export const socialLoginFacebook = async (data: object) => {
	const payload = { ...data };
	const response = await publicApiService.post({
		url: '/auth/social/facebook',
		payload,
	});
	return response;
};

// export const socialLoginTelegram = async (data: object, domain: string) => {
// 	const payload = { ...data };
// 	const response = await publicApiService.post({
// 		url: ` /auth/social/telegram/${domain}`,
// 		payload,
// 	});
// 	return response;
// };
export const socialLoginTelegram = async ({
	data,
	domain,
}: {
	data: object;
	domain: string;
}) => {
	const payload: string = JSON.stringify(data);
	const response = await publicApiService.post({
		url: `/auth/social/telegram/${domain}`,
		payload,
	});
	return response;
};

export const logOutUser = () => {
	localStorage.removeItem('game-user-token');
	localStorage.removeItem('game-user-secret');
	localStorage.removeItem('game-user-obj');
	window.location.reload();
	window.location.href = '/';
};
export const purchaseProductApi = async (data: object) => {
	const payload = { ...data };
	const response = await apiService(data).post({
		url: '/member/market-place/purchase-product',
		payload,
	});
	return response;
};

export const getSocialConfiguration = async () => {
	const response = await apiService().get(`/public/social/configuration`);
	return response;
};

export const completeRegistration = async (data: object) => {
	const payload = { ...data };
	const response = await apiService(data).post({
		url: '/auth/registration/complete-register',
		payload,
	});

	return response;
};
// /member/phone-number/otp

export const getAllNotification = async () => {
	const response = await apiService().get(`/member/notifications`);
	return response;
};

export const getEmailCategories = async () => {
	const response = await apiService().get('/data/email-categories');
	return response;
};

export const getUserUnsubscribedEmails = async () => {
	const response = await apiService().get('/member/email-category-exemptions');
	return response;
};

export const unsubscribeEmail = async (data: object) => {
	const payload = { ...data };
	const response = await apiService(data).put({
		url: '/member/email-category-exemptions',
		payload,
	});

	return response;
};

export const activateSubscriptionApi = async (data: object) => {
	const payload = { ...data };
	const response = await apiService(data).post({
		url: '/member/subscription',
		payload,
	});
	return response;
};

export const getSubscriptionDetail = async () => {
	const response = await apiService().get('/member/subscription');
	return response;
};

export const toggleSubscriptionApi = async (data: object) => {
	const payload = { ...data };
	const response = await apiService(data).post({
		url: '/member/subscription/toggle',
		payload,
	});
	return response;
};

export const switchSubscriptionApi = async (data: object) => {
	const payload = { ...data };
	const response = await apiService(data).post({
		url: '/member/subscription/plan/switch',
		payload,
	});
	return response;
};

export const postTelegramSocialTaskApi = async (data: object) => {
	const payload = Object.fromEntries(
		Object.entries(data).filter(([, value]) => value !== undefined)
	);
	const response = await apiService(payload).post({
		url: '/member/game-center/telegram/task',
		payload,
	});
	return response;
};

export const getTelegramDoneTaskApi = async () => {
	const response = await apiService().get('/member/game-center/telegram/task');
	return response;
};

export const joinTournamentApi = async (data: object) => {
	const payload = Object.fromEntries(
		Object.entries(data).filter(([, value]) => value !== undefined)
	);
	const response = await apiService(payload).post({
		url: '/member/game-center/telegram/tournament',
		payload,
	});
	return response;
};

export const getJoinedTournamentApi = async () => {
	const response = await apiService().get(
		'/member/game-center/telegram/tournament/joined'
	);
	return response;
};

export const getTournamentRewardApi = async (query: string) => {
	const response = await apiService({ tournamentId: query }).get(
		`/member/game-center/telegram/tournament/reward/view?tournamentId=${query}`
	);
	return response;
};

export const liveTournamentResultApi = async (query: string) => {
	const response = await apiService({ tournamentId: query }).get(
		`/member/game-center/telegram/tournament/result?tournamentId=${query}`
	);
	return response;
};

export const claimTournamentRewardApi = async (data: object) => {
	const payload = { ...data };
	const response = await apiService(data).post({
		url: '/member/game-center/telegram/tournament/claim',
		payload,
	});
	return response;
};
