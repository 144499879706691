import { ReactNode, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Logo from 'assets/icons/Hammer-logo-2.png';
import { storeReferralUsername } from 'utils';

type ILayoutProps = {
	children: ReactNode;
	theme: string;
	toggleTheme: (e: string) => void;
};

export const AuthLayout2 = ({ children }: ILayoutProps) => {
	const navigate = useNavigate();
	const [params] = useSearchParams();

	useEffect(() => {
		const refName = params.get('ref');
		if (refName) {
			// TODO: should this work when the user is already logged in?
			storeReferralUsername(refName);
		}
	}, [params]);

	return (
		<div className="bg-primary w-screen min-h-screen overflow-hidden">
			<main className="w-full min-h-0 md:min-h-[90vh] overflow-hidden">
				<main className="w-full h-full md:pt-6 pt-3 pb-3 px-4 md:px-6 sm:flex items-center justify-center max-w-[480px] mx-auto">
					<div>
						<img
							src={Logo}
							alt="logo"
							onClick={() => navigate('/')}
							className="cursor-pointer max-h-[100px] w-auto py-2 mb-5 mt-10"
						/>
						{children}
					</div>
				</main>
			</main>
		</div>
	);
};
