import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// interface Transactions {
// 	itemsList: object[];
// 	paginator: object;
// }
interface IInitialState {
	allTransactions: object;
}

export const initialState: IInitialState = {
	allTransactions: {},
};

export const transactionsSlice = createSlice({
	name: 'hammer-games',
	initialState,
	reducers: {
		getAllTransactions: (state, action: PayloadAction<object>) => {
			state.allTransactions = action.payload;
		},
	},
});

export const transactionsActions = transactionsSlice.actions;
export const transactionsReducer = transactionsSlice.reducer;
