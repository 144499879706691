import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// interface Transactions {
// 	itemsList: object[];
// 	paginator: object;
// }
interface IInitialState {
	allJackpotBids: object;
	gameCenterData: object;
	marketPlaceProduct: object;
	gamesData: object;
}

export const initialState: IInitialState = {
	allJackpotBids: {},
	gameCenterData: {},
	marketPlaceProduct: {},
	gamesData: {},
};

export const gameCenterSlice = createSlice({
	name: 'hammer-games',
	initialState,
	reducers: {
		getAllJackpotBids: (state, action: PayloadAction<object>) => {
			state.allJackpotBids = action.payload;
		},
		addGameCenterData: (state, action: PayloadAction<object>) => {
			state.gameCenterData = action.payload;
		},
		addMarketProduct: (state, action: PayloadAction<object>) => {
			state.marketPlaceProduct = action.payload;
		},
		getGamesData: (state, action: PayloadAction<object>) => {
			state.gamesData = action.payload;
		},
	},
});

export const gameCenterActions = gameCenterSlice.actions;
export const gameCenterReducer = gameCenterSlice.reducer;
