//import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import walletConnectSetup from './utils/wallet-connect-setup';
import './styles/index.scss';
import './i18nConfig';

const queryClient = new QueryClient();

walletConnectSetup();

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	//	<React.StrictMode>
	<QueryClientProvider client={queryClient}>
		<App />
	</QueryClientProvider>
	//	</React.StrictMode>
);
reportWebVitals();

if (process.env.NODE_ENV !== 'development') {
	console.log = () => {};
	console.error = () => {};
	console.debug = () => {};
}
