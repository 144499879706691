import { useState, Fragment, ReactNode, FC } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ReactComponent as Close } from '../../assets/icons/close-green.svg';

type Props = {
	children: ReactNode;
	onClose?: () => void;
	showCloseIcon?: boolean;
	showBgColor?: boolean;
	modalClass?: string;
	modalContClass?: string;
	isToastModal?: boolean;
	toastArray?: number;
	isdarkBg?: boolean;
	childrenClass?: string;
	layerClass?: string;
};

export const ModalLayout: FC<Props> = ({
	children,
	onClose,
	showCloseIcon = true,
	showBgColor = true,
	modalClass,
	modalContClass,
	isToastModal,
	isdarkBg,
}) => {
	const [open] = useState(true);
	// console.log('modalClass- ', modalClass);

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-10"
				onClose={() => onClose && onClose()}
				style={{ zIndex: 1000 }}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 h-screen w-screen transition-opacity" />
				</Transition.Child>

				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div
						className={`flex ${
							isToastModal
								? 'justify-end items-start'
								: 'justify-center items-center'
						}  bg-bg-dark ${
							isdarkBg ? 'bg-opacity-96' : 'bg-opacity-80'
						}  min-h-full text-center ${modalContClass}`}
					>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel
								as="div"
								onClick={(e: any) => e.stopPropagation()}
								className={` ${
									showBgColor ? 'bg-[#1D212F]' : 'bg-transparent'
								} relative  rounded-lg text-left overflow-hidden shadow-xl transform transition-all lg:p-8 lg:px-12 p-5 xl:max-w-[32%] w-full mx-5 ${modalClass}`}
							>
								<div className="this-here w-full">
									{showCloseIcon && (
										<button
											type="button"
											onClick={() => onClose && onClose()}
											className="absolute flex items-center justify-center top-6 lg:right-8 right-3 lg:h-[48px] lg:w-[48px] h-[36px]  w-[36px] rounded-full bg-bg-dark 
                                    "
										>
											<Close className="lg:h-[29px] lg:w-[29px] h-[20px] w-[20px] z-10" />
										</button>
									)}

									<div className="w-full">{children}</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};
