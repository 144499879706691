import bgAvatar from 'assets/images/bg-avatar.svg';

const Avatar = ({ text, size }: { text: string; size?: string }) => {
	return (
		<div
			style={{
				backgroundImage: `url(${bgAvatar})`,
				backgroundSize: 'contain',
				backgroundRepeat: 'no-repeat',
			}}
			className={` flex justify-center items-center text-white text-lg ${
				size ? size : 'w-[2.875rem] h-[2.875rem]'
			}`}
		>
			{text?.charAt(0)}
		</div>
	);
};

export default Avatar;
