import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IRoomsProps {
	general: number;
	odogwuHunter: number;
	hammerSpin: number;
	luckyDraw: number;
}

interface IInitialState {
	unreadMessagesCount: IRoomsProps | null;
}

export const initialState: IInitialState = {
	unreadMessagesCount: null,
};

export const chatSlice = createSlice({
	name: 'hammer-games',
	initialState,
	reducers: {
		getUnreadMessagesCount: (state, action: PayloadAction<IRoomsProps>) => {
			state.unreadMessagesCount = action.payload;
		},
	},
});

export const chatActions = chatSlice.actions;
export const chatReducer = chatSlice.reducer;
