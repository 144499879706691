import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import codes from 'country-calling-code';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

export interface TextInputProps
	extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
	passwordInput?: boolean;
	prefixLabel?: any;
	suffixLabel?: ReactNode;
	setValue?: (e?: any) => void;
	required?: boolean;
	label: string;
	labelIcon?: string;
	subLabel?: ReactNode;
	errorMessage?: any;
	inputInfo?: ReactNode;
	icon?: ReactNode;
	iconClick?: () => void;
	prefix?: string;
	onClick?: (e: any) => void;
	onChange?: (e: any) => void;
	max?: any;
	country?: string;
}

const PhoneInput = forwardRef(
	(
		{
			name,
			value,
			// onClick,
			onChange,
			// setValue,
			label,
			labelIcon,
			subLabel,
			// iconClick,
			required,
			errorMessage,
			inputInfo,
			country,
			// icon,
			className,
			max,
			...rest
		}: TextInputProps,
		ref: React.Ref<HTMLInputElement>
	) => {
		const countryCode = codes.find((e) => e.isoCode2 === country);

		return (
			<div className={`${className}`}>
				<div className="flex justify-between items-center">
					{label && (
						<div className="w-full flex items-center mb-1">
							<label
								className={`text-[10px] md:text-[14px] capitalize leading-[21px] text-grey font-medium `}
							>
								{label}
							</label>
							{labelIcon && <img src={labelIcon} alt="" className="ml-2" />}
							{required && <span className="text-text-red ml-[3px]"> * </span>}
						</div>
					)}
					{subLabel && <>{subLabel}</>}
				</div>

				<main
					className={`w-full mb-3 flex items-center justify-between relative`}
				>
					<div className="absolute left-4">
						{countryCode && (
							<div className="flex gap-1 items-center">
								<span>+{countryCode?.countryCodes[0]}</span>
								{country && getUnicodeFlagIcon(country)}
							</div>
						)}
						{/* <select
							onChange={onChange}
							value={value}
							name={'phoneCode'}
							className="bg-transparent outline-none border-none w-[5.4rem]"
						>
							{codes &&
								codes.sort()?.map((code, i) => (
									<option key={i} value={code.countryCodes[0]}>
										+{code.countryCodes[0]} {getUnicodeFlagIcon(code?.isoCode2)}
									</option>
								))}
						</select> */}
					</div>

					<input
						{...rest}
						type={'number'}
						ref={ref}
						name={name}
						// value={value}
						onChange={onChange}
						max={max}
						autoComplete="off"
						className={`w-full h-[56px] rounded-[43px] bg-[#0F121D] focus:bg-[#0F121D] outline-0 px-3 text-[0.875rem] text-center focus:outline-none focus:text-white ${
							value ? 'text-white border-[#84C70D]' : 'text-grey'
						} font-medium placeholder-[#232838] border-2 border-[#232838] focus:border-[#84C70D] leading-normal`}
					/>
				</main>
				{inputInfo && <>{inputInfo}</>}
				{errorMessage && (
					<div className="w-full ">
						<span className="text-text-red text-[12px] ">{errorMessage}</span>
					</div>
				)}
			</div>
		);
	}
);

PhoneInput.displayName = 'MyApp';

export default PhoneInput;
